import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Form, Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useDropdowns from 'src/hooks/useDropdowns'
import {
  AddEntertainmentVideos,
  GetChapters,
  GetCourses,
  GetExerciseDetails,
  GetLessons,
  GetUnits,
} from 'src/redux/actions'
import { filterByKeyValue, getFilePreview } from 'src/util/helpers'
import { uploadToS3 } from 'src/util/uploadToS3'
import * as Yup from 'yup'

import { StatusList } from '../../util/constants'
import DropdownMenu from '../helperComponents/DropdownMenu'
import SubtitleEditor from '../shared/SubtitleEditor'

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
  course: Yup.string().required('Required'),
  unit: Yup.string().required('Required'),
  chapter: Yup.string().required('Required'),
  // lesson: Yup.string().required('Required'),
  video: Yup.string().required('Please enter video url'),
})

const FORM_FIELDS = {
  TRANSCRIPT: 'transcript',
}

const useStyles = makeStyles(() =>
  createStyles({
    invisible: { visibility: 'hidden' },
  })
)

const AddEditVideo = ({ selectedRow, toggleDrawer }) => {
  const initialValues = {
    name: selectedRow?.name || 'untitled',
    lesson: selectedRow?.lesson?._id || '',
    course: selectedRow?.course?._id || '',
    chapter: selectedRow?.chapter?._id || '',
    unit: selectedRow?.unit?._id || '',
    video: selectedRow?.video || '',
    videoType: selectedRow?.type || '',
    coin: selectedRow?.coin || '',
    videoIframe: selectedRow?.videoIframe || '',
    imageUrl: selectedRow?.imageUrl || '',
    isActive:
      selectedRow && selectedRow.isActive === undefined
        ? true
        : selectedRow && selectedRow?.isActive,
  }
  const dispatch = useDispatch()
  const { dropdowns } = useDropdowns()
  const [totalUnits, setTotalUnits] = useState([])
  const [totalChapters, setTotalChapters] = useState([])
  const [totalLessons, setTotalLessons] = useState([])
  const [openSubtitleEditor, setOpenSubtitleEditor] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    setTotalUnits(dropdowns?.units)
    setTotalChapters(dropdowns?.chapters)
    setTotalLessons(dropdowns?.lessons)
  }, [dropdowns])

  const onCourseChange = useCallback(({ target: { value } }, setFieldValue) => {
    setTotalUnits(filterByKeyValue(dropdowns?.units, 'course', value))
    setTotalChapters([])
    setTotalLessons([])
    setFieldValue('chapter', '')
    setFieldValue('unit', '')
    setFieldValue('lesson', '')
  }, [])

  const onUnitChange = useCallback(({ target: { value } }, setFieldValue) => {
    setTotalChapters(filterByKeyValue(dropdowns?.chapters, 'unit', value))
    setTotalLessons([])
    setFieldValue('chapter', '')
    setFieldValue('lesson', '')
  }, [])

  const onChapterChange = useCallback(
    ({ target: { value } }, setFieldValue) => {
      setTotalLessons(filterByKeyValue(dropdowns?.lessons, 'chapter', value))
      setFieldValue('lesson', '')
    },
    []
  )

  useEffect(() => {
    dispatch(GetChapters())
    dispatch(GetUnits())
    dispatch(GetLessons())
    dispatch(GetCourses())
    dispatch(GetExerciseDetails())
  }, [])

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = values => {
    console.log('submitting')
    let dataToSubmit = selectedRow?._id
      ? { ...values, _id: selectedRow._id, lesson: null }
      : { ...values }
    toggleDrawer(false)
    dispatch(AddEntertainmentVideos(dataToSubmit))
  }

  const uploadFile = files => {
    uploadToS3(files[0])
  }

  const handleEditTranscript = () => {
    setOpenSubtitleEditor(!openSubtitleEditor)
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container>
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} Video
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <FormControl error={Boolean(errors.course)} fullWidth>
                      <DropdownMenu
                        label="Course"
                        name="course"
                        data={dropdowns?.courses}
                        value={values.course}
                        errorsMsg={errors.course}
                        onChange={event => {
                          handleChange(event)
                          onCourseChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl error={Boolean(errors.unit)} fullWidth>
                      <DropdownMenu
                        label="Unit"
                        name="unit"
                        data={totalUnits}
                        errorMsg={errors.unit}
                        value={values.unit}
                        onChange={event => {
                          handleChange(event)
                          onUnitChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl error={Boolean(errors.chapter)} fullWidth>
                      <DropdownMenu
                        label="Chapter"
                        name="chapter"
                        errorsMsg={errors.chapter}
                        data={totalChapters}
                        value={values.chapter}
                        onChange={event => {
                          handleChange(event)
                          onChapterChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      // error={Boolean(errors.lesson)}
                      isRequired={false}
                      fullWidth
                    >
                      <DropdownMenu
                        label="Lesson"
                        name="lesson"
                        errorsMsg={errors.lesson}
                        data={totalLessons}
                        value={values.lesson}
                        onChange={handleChange}
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.video && errors.video)}
                      fullWidth
                      helperText={touched.video && errors.video}
                      label="Video URL"
                      placeholder="Video Link"
                      margin="normal"
                      name="video"
                      type={'url'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.video}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth class={classes.invisible}>
                      <TextField
                        name={FORM_FIELDS.TRANSCRIPT}
                        value={values[FORM_FIELDS.TRANSCRIPT]}
                      />
                    </FormControl>
                    <Button
                      color="primary"
                      onClick={handleEditTranscript}
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                    >
                      {selectedRow?.subtitle ? 'Edit' : 'Upload'} Transcript
                    </Button>
                    <SubtitleEditor
                      subtitle={selectedRow?.subtitle}
                      handleClose={() => setOpenSubtitleEditor(false)}
                      open={openSubtitleEditor}
                      onSave={(value, formValues) => {
                        setFieldValue(FORM_FIELDS.TRANSCRIPT, value)
                        // handleSaveTranscript(value)
                        handleSubmit(formValues)
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      label={'Normal Video'}
                      control={
                        <Radio
                          name="videoType"
                          onChange={handleChange}
                          checked={values.videoType === 'normal'}
                          value={'normal'}
                        />
                      }
                      htmlFor="videoType"
                    ></FormControlLabel>
                    <FormControlLabel
                      label={'4L Video'}
                      control={
                        <Radio
                          name="videoType"
                          onChange={handleChange}
                          checked={values.videoType === '4L'}
                          value={'4L'}
                        />
                      }
                      htmlFor="videoType"
                    ></FormControlLabel>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Video Iframe"
                      multiline
                      rows={4}
                      placeholder="Video Iframe"
                      name="videoIframe"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.videoIframe}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Video Name"
                      placeholder="Video Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.coin && errors.coin)}
                      fullWidth
                      helperText={touched.coin && errors.coin}
                      label="Video Coin"
                      placeholder="Video Coin"
                      name="coin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.coin}
                      variant="outlined"
                    />
                  </Grid>
                  <>
                    <Grid item md={12} xs={12}>
                      <Stack direction="row" spacing={2} margin={1}>
                        <Grid direction="row" container spacing={1}>
                          <Grid item md={4} xs={4}>
                            <FormControlLabel
                              label={''}
                              htmlFor={`imageUrl`}
                              control={
                                <>
                                  <Input
                                    type="file"
                                    style={{
                                      display: 'none',
                                    }}
                                    onChange={e => {
                                      uploadFile(e.target.files)
                                      // handleChange()
                                    }}
                                    name={`imageUrl`}
                                    inputProps={{
                                      accept: 'image/*',
                                    }}
                                    id={`imageUrl`}
                                  />
                                  <Button variant="contained" component="span">
                                    {'Video Image'}
                                  </Button>
                                </>
                              }
                            ></FormControlLabel>
                          </Grid>
                          <Grid item md={8} xs={8}>
                            {getFilePreview(values[`imageUrl`])}
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                  </>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddEditVideo
