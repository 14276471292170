import { Box } from '@material-ui/core'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'

import { GetDropdowns } from '../redux/actions'

const Dashboard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    //Load courses, units, chapters, lessons dropdowns in advance for smooth process
    dispatch(GetDropdowns())
  }, [])

  return (
    <>
      <Helmet>
        <title>Dashboard | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <p>Coming soon!!</p>
      </Box>
    </>
  )
}

export default Dashboard
