// ErrorNotification.jsx

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { HIDE_ERROR } from 'src/redux/errorReducer'
import Toaster from 'src/components/helperComponents/Toaster'

const ErrorNotification = props => {
  const isOpen = useSelector(state => state.errorReducer.isOpen)
  const error = useSelector(state => state.errorReducer.error)

  const dispatch = useDispatch()

  function handleClose() {
    dispatch({ type: HIDE_ERROR })
  }
  let msg = ''
  if (error && error.data) {
    msg = error.data.errors.map(x => x.msg).join('\n ')
  } else if (error && !error.data) {
    msg = error
  }
  return (
    <>
      {isOpen && error && (
        <Toaster msg={msg} severity="error" onClose={handleClose} />
      )}
    </>
  )
}

export default ErrorNotification
