import { useEffect, useState } from 'react'
import useDropdowns from 'src/hooks/useDropdowns'
import { filterByKeyValue } from 'src/util/helpers'

const useCourseUnitChapterState = values => {
  const [unitsList, setUnitsList] = useState([])
  const [chaptersList, setChaptersList] = useState([])

  const { dropdowns } = useDropdowns()
  // change units and chapters if course value changes
  useEffect(() => {
    if (dropdowns) {
      setUnitsList(filterByKeyValue(dropdowns.units, 'course', values.course))
      setChaptersList(filterByKeyValue(dropdowns.chapters, 'unit', values.unit))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.course, dropdowns])

  // change the chapters list if only unit value changes
  useEffect(() => {
    if (dropdowns?.chapters)
      setChaptersList(filterByKeyValue(dropdowns.chapters, 'unit', values.unit))
  }, [dropdowns?.chapters, values.unit])

  return {
    chaptersList,
    coursesList: dropdowns?.courses || [],
    unitsList,
  }
}

export default useCourseUnitChapterState
