export default (function () {
  function _setAuthInfo(authInfo) {
    if (authInfo) {
      localStorage.setItem('authInfo', JSON.stringify(authInfo))
      localStorage.setItem('token', authInfo.token)
    }
  }
  function _getAuthInfo() {
    return JSON.parse(localStorage.getItem('authInfo'))
  }
  function _getAuthToken() {
    return localStorage.getItem('token')
  }
  function _clearStorage() {
    localStorage.removeItem('token')
    localStorage.removeItem('authInfo')
  }
  return {
    setAuthInfo: _setAuthInfo,
    getAuthInfo: _getAuthInfo,
    getAuthToken: _getAuthToken,
    clearStorage: _clearStorage,
  }
})()
