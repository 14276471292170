import { Box, Container, Drawer } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddEditExerciseDetails from 'src/components/exercise/AddExcerciseDetails'
import AddButton from 'src/components/helperComponents/AddButton'
import DataTableNew from 'src/components/helperComponents/DataTable/DataTableNew'
import DialogComponent from 'src/components/helperComponents/DialogComponent'
import useQuery from 'src/hooks/useQuery'
import {
  DeleteExerciseMasterDetailById,
  GetExerciseDetails,
} from 'src/redux/actions'
import { GetExerciseDetailTableColumns } from 'src/util/columns'

const ExerciseDetailList = () => {
  const dispatch = useDispatch()
  const query = useQuery()

  const { exerciseDetails } = useSelector(store => store.mainReducer)

  const [recordToEdit, setRecordToEdit] = useState()
  const [recordToDelete, setRecordToDelete] = useState()
  const [customFilterQuery, setCustomFilterQuery] = useState()
  useEffect(() => {
    if (recordToEdit) {
      const newRecord = exerciseDetails.find(
        item => item.id === recordToEdit.id
      )
      if (newRecord) {
        setRecordToEdit(newRecord)
      }
    }
  }, [exerciseDetails])
  const onDeleteRow = () => {
    if (recordToDelete) {
      dispatch(DeleteExerciseMasterDetailById(recordToDelete))
      setRecordToDelete()
    }
  }

  return (
    <>
      <title>Exercise Details | Langistan</title>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            type="Exercise Detail"
            onClick={() => setRecordToEdit({})}
            dispatcher={GetExerciseDetails}
            setCustomFilterQuery={setCustomFilterQuery}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 700,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditExerciseDetails
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <DataTableNew
              data={exerciseDetails}
              columns={GetExerciseDetailTableColumns(
                setRecordToEdit,
                setRecordToDelete
              )}
              apiName={'exerciseDetails'}
              filters={query}
              customFilterQuery={customFilterQuery}
            />
          </Box>
          {recordToDelete && (
            <DialogComponent
              title="Exercise Detail?"
              description="Deleted exercise detail can not be recovered. Still want to delete?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default ExerciseDetailList
