import './index.css'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import MTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import omitBy from 'lodash/omitBy'
import React from 'react'
import { createListFetcher } from 'src/api'
import DialogComponent from 'src/components/helperComponents/DialogComponent'

import TablePaginationActions from './actions'
import { getAllLeafColumnDefs, getColumnId } from './column-utils'
// import FreeTextSearchInput from './FreeTextSearchInput'

export function LocalTable({
  columns,
  name,
  rowActions = true,
  setRowSelectionData,
  ...props
}) {
  const [selectedRecord, setSelectedRecord] = React.useState(null)
  const [searchText, setSearchText] = React.useState(null)
  const [rowSelection, setRowSelection] = React.useState({})

  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const handlePaginationChange = (value) => {
    props?.setPagination(value);
    setPagination(value);
  }

  // const fetchDataOptions = omitBy(
  //   {
  //     pageIndex,
  //     pageSize,
  //     searchText,
  //     additionalFilters: props.additionalFilters
  //       ? props.additionalFilters
  //       : null,
  //   },
  //   val => val?.toString()?.trim() === '' || val === null || val === undefined
  // )

  // const { data, isLoading, isFetching, refetch } = useQuery(
  //   [name, fetchDataOptions],
  //   createListFetcher(name),
  //   { keepPreviousData: true }
  // )

  // const isFetchingData = isLoading || isFetching

  const rows = React.useMemo(() => props.data?.docs || [], [props.data?.docs])

  const table_data = React.useMemo(
    () =>
      props.isFetchingData && !rows?.length
        ? [...Array(10).fill(null)].map(() =>
            Object.assign(
              {},
              ...getAllLeafColumnDefs(columns).map(col => ({
                [getColumnId(col)]: null,
              }))
            )
          )
        : rows,
    [columns, rows, props.isFetchingData]
  )

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data: table_data,
    pageCount: props?.data?.totalPages ?? -1,
    columns,
    state: {
      pagination,
      rowSelection,
    },
    getRowId: (data, index) => data._id || index,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: handlePaginationChange,
    manualPagination: true,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const handleEditClick = record => {
    props.onEditClick(record)
  }

  const handleDeleteClick = record => {
    setSelectedRecord(record)
  }

  const handleDeleteConfirm = async () => {
    await props.onDeleteClick(selectedRecord.id)
    setSelectedRecord(null)
  }

  React.useEffect(() => {
    if (setRowSelectionData && table.getSelectedRowModel().flatRows.length) {
      setRowSelectionData(table.getSelectedRowModel().flatRows)
    }
  }, [table.getSelectedRowModel().flatRows])

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <MTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {props.onEditClick ? (
                  <TableCell width="30" key="edit_cell" />
                ) : null}
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => {
              return (
                <TableRow key={row.id}>
                  {rowActions && (
                    <EditCell
                      record={row}
                      onEditClick={handleEditClick}
                      onDeleteClick={handleDeleteClick}
                    />
                  )}
                  {row.getVisibleCells().map(cell => {
                    return (
                      <TableCell key={cell.id}>
                        {!props.isFetchingData ? (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        ) : (
                          <Skeleton
                            varient="text"
                            animation="wave"
                            height={20}
                          />
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </MTable>
      </TableContainer>
      {!props?.isLoading && (
        <TablePagination
          rowsPerPageOptions={[
            10,
            20,
            50,
            { label: 'All', value: rows.length },
          ]}
          component="div"
          count={props?.data?.totalDocs ?? 0}
          rowsPerPage={pageSize}
          page={pageIndex}
          disableNextButton={!table.getCanNextPage()}
          disablePrevButton={!table.getCanPreviousPage()}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={(_, page) => {
            table.setPageIndex(page)
          }}
          onRowsPerPageChange={e => {
            const size = e.target.value ? Number(e.target.value) : 10
            table.setPageSize(size)
          }}
          ActionsComponent={TablePaginationActions}
        />
      )}
      {selectedRecord && (
        <DialogComponent
          title={`Delete ${singularize(name)}`}
          onDialogOk={handleDeleteConfirm}
          onDialogCancel={() => setSelectedRecord(null)}
        />
      )}
    </Box>
  )
}

function EditCell({ onEditClick, onDeleteClick, record }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  if (!onEditClick) return null

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEditClick = e => {
    e.stopPropagation()
    onEditClick(record.original)
    handleClose()
  }

  const handleDeleteClick = e => {
    e.stopPropagation()
    onDeleteClick(record.original)
    handleClose()
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <TableCell>
      <IconButton
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {onEditClick ? (
          <MenuItem>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
              <Typography>Edit</Typography>
            </IconButton>
          </MenuItem>
        ) : null}
        {onDeleteClick ? (
          <MenuItem>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
              <Typography>Delete</Typography>
            </IconButton>
          </MenuItem>
        ) : null}
      </Menu>
    </TableCell>
  )
}

// make plural to singular
function singularize(name) {
  return name.endsWith('s') ? name.slice(0, -1) : name
}
