import { Navigate } from 'react-router-dom'

import DashboardLayout from './components/DashboardLayout'
import MainLayout from './components/MainLayout'
import SubtitleEditor from './components/shared/SubtitleEditor'
import UnitDetails from './components/unit/UnitDetails'
import Account from './pages/Account'
import BannerList from './pages/BannerList'
import ChapterList from './pages/ChapterList'
import CourseList from './pages/CourseList'
import CustomerList from './pages/CustomerList'
import Dashboard from './pages/Dashboard'
import EntertainmentVideoList from './pages/EntertainmentVideoList'
import EntertainmentVocabularyList from './pages/EntertainmentVocabularyList'
import EntertainmentTranscriptionList from './pages/exercise/EntertainmentTranscriptionList'
import ExerciseDetailList from './pages/exercise/ExerciseDetailList'
import ExerciseMasterList from './pages/exercise/ExerciseMasterList'
import LessonList from './pages/LessonList'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import NotificationList from './pages/NotificationsList'
import ProductList from './pages/ProductList'
import Register from './pages/Register'
import ReportList from './pages/ReportList'
import ServiceLogList from './pages/ServiceLogList'
import Settings from './pages/Settings'
import UnitList from './pages/UnitList'
import UserFeedbacksList from './pages/UserFeedbacksList'

const routes = isLoggedIn => {
  let navigateToLandingPage = undefined
  const landingPath = window.localStorage.getItem('landingPath')
  if (!isLoggedIn && window.location.pathname.indexOf('login') === -1) {
    window.localStorage.setItem(
      'landingPath',
      window.location.pathname + window.location.search
    )
  } else if (landingPath && landingPath !== '/') {
    navigateToLandingPage = landingPath
  }

  const authSucess =
    (window.localStorage?.authInfo && JSON.parse(window.localStorage?.authInfo)?.success) ||
    false

  return [
    {
      path: '/app',
      element:
        isLoggedIn || authSucess ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: 'account', element: <Account /> },
        { path: 'customers', element: <CustomerList /> },
        { path: 'languages', element: <CourseList /> },
        { path: 'courses', element: <UnitList /> },
        { path: 'unit-details', element: <UnitDetails /> },
        { path: 'chapters', element: <ChapterList /> },
        { path: 'reports', element: <ReportList /> },
        { path: 'notifications', element: <NotificationList /> },
        { path: 'lessons', element: <LessonList /> },
        { path: 'banners', element: <BannerList /> },
        {
          path: 'entertainmentVideos',
          element: <EntertainmentVideoList />,
        },
        {
          path: 'entertainmentTranscriptions',
          element: <EntertainmentTranscriptionList />,
        },
        {
          path: 'entertainmentVocabularies',
          element: <EntertainmentVocabularyList />,
        },
        {
          path: 'exerciseDetails',
          element: <ExerciseDetailList />,
        },
        {
          path: 'exerciseMasters',
          element: <ExerciseMasterList />,
        },
        {
          path: 'feedbacks',
          element: <UserFeedbacksList />,
        },
        {
          path: 'servicelogs',
          element: <ServiceLogList />,
        },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'products', element: <ProductList /> },
        { path: 'settings', element: <Settings /> },
        { path: 'subtitleEditor', element: <SubtitleEditor /> },
        //{ path: "/", element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: !isLoggedIn ? (
        <MainLayout />
      ) : navigateToLandingPage &&
        window.localStorage.getItem('landingPath') ? (
        <Navigate to={window.localStorage.getItem('landingPath')} />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        // { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]
}

export default routes
