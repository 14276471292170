import { useContext } from 'react'
import { UserContext } from 'src/contexts/UserContext'

export default function useToken() {
  const context = useContext(UserContext)
  if (!context) {
    throw Error('useToken shoudle be used in UserProvider')
  }
  return context
}
