import {
  Button,
  Input,
  FormControlLabel,
  FormGroup,
  TextField,
  Radio,
  Stack,
  Box,
  Divider,
  Grid,
} from '@material-ui/core'
import { Fragment, useEffect, useState } from 'react'
import DeleteIconComponent from 'src/components/helperComponents/DeleteIconComponent'
import { getFilePreview } from 'src/util/helpers'
import WatchVideoOrImageField from './Fields/WatchVideoOrImageField'

const RightTextOption = ({
  numberOfRows = 3,
  hasVideo = false,
  onDataChange,
  onMediaRemove,
  childData,
  isDisabled,
}) => {
  const [rows, addRows] = useState(numberOfRows)
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(childData)
  }, [childData])

  const changeHandler = (
    { target: { name, value, files, checked } },
    fieldLabel
  ) => {
    let finalValues
    if (files) {
      let file = files[0]
      file.fieldLabel = fieldLabel
      finalValues = {
        ...values,
        [name]: file,
      }
    } else {
      finalValues = {
        ...values,
        [name]: name === 'isImage' ? checked : value,
      }
    }
    setValues(finalValues)
    onDataChange(finalValues)
  }

  return (
    <Box sx={{ p: 2, border: '1px solid grey' }}>
      <FormGroup>
        <WatchVideoOrImageField
          isImage={values.isImage}
          onChange={e => changeHandler(e)}
          isDisabled={isDisabled}
          value={values.isImage ? values.exerciseImage : values.exerciseVideo}
        />

        {hasVideo ? (
          <Stack direction="row" spacing={2} margin={1}>
            <TextField
              fullWidth
              label="Video Url"
              name={'video'}
              onChange={changeHandler}
              value={values.video}
              variant="outlined"
            />
          </Stack>
        ) : (
          <Fragment>
            <Stack direction="row" spacing={2} margin={1}>
              <Grid direction="row" container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`audio`}
                    control={
                      <>
                        <Input
                          style={{ display: 'none' }}
                          onChange={event => changeHandler(event, 'Audio')}
                          name={`audio`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`audio`}
                          type="file"
                          // disabled={!!values?.audio}
                        />
                        <Button variant="contained" component="span">
                          {'Audio'}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={8} xs={8}>
                  {getFilePreview(values[`audio`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`audio`] && (
                    <DeleteIconComponent
                      onClick={() => onMediaRemove('audio', values)}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key="audioDivider" />
            <Stack direction="row" spacing={2} margin={1}>
              <Grid direction="row" container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`audioSlow`}
                    control={
                      <>
                        <Input
                          style={{ display: 'none' }}
                          onChange={event => changeHandler(event, 'Slow_Audio')}
                          name={`audioSlow`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`audioSlow`}
                          type="file"
                          // disabled={!!values?.audioSlow}
                        />
                        <Button variant="contained" component="span">
                          {'Slow_Audio'}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={8} xs={8}>
                  {getFilePreview(values[`audioSlow`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`audioSlow`] && (
                    <DeleteIconComponent
                      onClick={() => onMediaRemove('audioSlow', values)}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key="slowAudioDivider" />
          </Fragment>
        )}
        {[...Array(rows)].map((el, index) => {
          const optionKey = 'option' + (index + 1)
          const nativeOptionKey = 'nativeOption' + (index + 1)

          return (
            <Fragment key={index + 'Fragment'}>
              <Stack key={index + 'row'} direction="row" spacing={2} margin={1}>
                <FormControlLabel
                  label={'Option' + (index + 1)}
                  control={<></>}
                  htmlFor="answer"
                ></FormControlLabel>
                <TextField
                  fullWidth
                  label="Words"
                  name={optionKey}
                  onChange={changeHandler}
                  required
                  value={values[optionKey]}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Native Words"
                  name={nativeOptionKey}
                  onChange={changeHandler}
                  value={values[nativeOptionKey]}
                  variant="outlined"
                />
                <FormControlLabel
                  label={''}
                  control={
                    <Radio
                      name="answer"
                      onChange={changeHandler}
                      checked={values.answer === optionKey}
                      value={optionKey}
                    />
                  }
                  htmlFor="answer"
                  disabled={!values[`option${index + 1}`]}
                ></FormControlLabel>
              </Stack>
              <Divider key={`${index + 1}Divider`} />
            </Fragment>
          )
        })}
      </FormGroup>
    </Box>
  )
}

export default RightTextOption
