import { Helmet } from 'react-helmet'
import { Box, Container } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { DeleteServiceLogById, GetServiceLogs } from 'src/redux/actions'

import DataTable from 'src/components/helperComponents/DataTable'
import { GetServiceLogColumns } from 'src/util/columns'
import DialogComponent from 'src/components/helperComponents/DialogComponent'

const ServiceLogList = () => {
  const dispatch = useDispatch()

  const { serviceLogs, loading } = useSelector(store => store.mainReducer)

  const [recordToDelete, setRecordToDelete] = useState()

  useEffect(() => {
    dispatch(GetServiceLogs())
  }, [])

  const onDeleteRow = () => {
    if (recordToDelete) {
      dispatch(DeleteServiceLogById(recordToDelete))
      setRecordToDelete()
    }
  }

  return (
    <>
      <Helmet>
        <title>Service Logs | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <DataTable
              data={serviceLogs}
              columns={GetServiceLogColumns(setRecordToDelete)}
              loading={loading}
            />
          </Box>
          {recordToDelete && (
            <DialogComponent
              title="Delete Log?"
              description="Really want to delete this service log?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default ServiceLogList
