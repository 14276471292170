import axios from 'axios'

import config from './config'

export const uploadToS3 = async (file: File) => {
  try {
    const { name, type } = file
    const response = await axios.post(`${config.API_URI}getPreSignUrl`, {
      filename: name,
      contentType: type,
    })

    const options = {
      headers: {
        'Content-Type': type,
      },
      withCredentials: false,
    }

    const signedUrl = response.data
    await axios.put(signedUrl, file, options)

    return response.data
  } catch (err) {
    console.log(err)
    throw err
  }
}
