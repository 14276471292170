import { API_URI } from './config'

const allowedFileNames = [
  'audio',
  'slowaudio',
  'option1',
  'option2',
  'option3',
  'option4',
  'option5',
  'option6',
  'option7',
  'option8',
  'option9',
  'option10',
  'option1File',
  'option2File',
  'option3File',
  'option4File',
  'option5File',
  'option6File',
  'option7File',
  'option8File',
  'option9File',
  'option10File',
]

export const LOADING_ERROR = asset =>
  `Error during loading ${asset}, Please try again.`
export const ADD_ERROR = asset =>
  `Error during adding/updating ${asset}, Please try again.`
export const DELETE_ERROR = asset =>
  `Error during deleting ${asset}, Please try again.`

export const getFiles = values => {
  let filesToReturn = []
  for (let value in values) {
    if (typeof values[value] === 'object' && allowedFileNames.includes(value)) {
      filesToReturn.push(values[value])
    }
  }
  return filesToReturn
}

export const isImage = file =>
  typeof file === 'string' && file.match(/\.(png|jpg|jpeg|svg)$/)
export const isAudio = file =>
  typeof file === 'string' && file.match(/\.(mp3|ogv)$/)
export const isVideo = file =>
  typeof file === 'string' && file.match(/\.(mp4|mpeg-4|avi|mov|3gp)$/)

const getFileUrl = fileName => {
  return `https://cdn.langistan.com/media/${fileName}`
  // return `${API_URI}download/${file}`
}

export const getFilePreview = (file, fileType) => {
  let fileToReturn = null

  if (file?.type?.includes('image') || (file && isImage(file))) {
    fileToReturn = (
      <img
        alt=""
        src={isImage(file) ? getFileUrl(file) : URL.createObjectURL(file)}
        height={100}
        width={200}
      />
    )
  } else if (file?.type?.includes('audio') || (file && isAudio(file))) {
    fileToReturn = (
      <audio controls>
        <source
          src={isAudio(file) ? getFileUrl(file) : URL.createObjectURL(file)}
          type={file.type}
        />
        Your browser does not support the audio element.
      </audio>
    )
  } else if (file && fileType === 'video') {
    fileToReturn = (
      <video
        width="320"
        height="240"
        controls
        src={isVideo(file) ? getFileUrl(file) : file}
      >
        Your browser does not support the video tag.
      </video>
    )
  }
  return fileToReturn
}

export const sortedByOrder = data => {
  return data.sort(
    (firstEl, secondEl) => firstEl?.sortOrder - secondEl?.sortOrder
  )
}

export const filterByKeyValue = (list = [], key, value) => {
  if (list && key && value) {
    return list.filter(item => item[key] === value)
  }
  return list
}

//This check is only for the old data which was come from migration.
// Right now we don't support lessons which has more than one Video
export const getLessonsWithLessThanTwoVideos = lessons => {
  if (lessons) {
    return lessons.filter(
      lesson => lesson.videos?.length === 0 || lesson.videos?.length === 1
    )
  }
  return lessons
}

export const returnTime = time => {
  let items = []
  for (let i = 0; i <= time; i++) {
    items.push({ key: i, value: i })
  }
  return items
}
export const minutes = returnTime(59)
export const hrs = returnTime(50)
