import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { EditReport } from 'src/redux/actions'
import DropdownMenu from 'src/components/helperComponents/DropdownMenu'
import { IssueTypeStatusList, IssueTypeList } from 'src/util/constants'
import LoadingButton from 'src/components/helperComponents/LoadingButton'
import { useState } from 'react'

const AddReport = ({ selectedRow, toggleDrawer }) => {
  const initialValues = {
    userName: (selectedRow && selectedRow?.userName) || '',
    comments: (selectedRow && selectedRow?.comments) || '',
    issueType: (selectedRow && selectedRow?.issueType) || '',
    status: (selectedRow && selectedRow?.status) || '',
    adminComments: (selectedRow && selectedRow?.adminComments) || '',
    userEmail: (selectedRow && selectedRow?.userEmail) || '',
    sendUpdatetoUser: false,
    reportLocation: (selectedRow && selectedRow?.reportLocation) || '',
    exerciseDetailUrl: (selectedRow && selectedRow?.exerciseDetailUrl) || '',
    exerciseMasterUrl: (selectedRow && selectedRow?.exerciseMasterUrl) || '',
    vocabularyUrl: (selectedRow && selectedRow?.vocabularyUrl) || '',
  }
  const [sendEmail, setSendEmail] = useState(false)
  const dispatch = useDispatch()

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = values => {
    const reqData = selectedRow._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    toggleDrawer(false)
    reqData.isComingFromAdminPanel = true
    dispatch(EditReport(reqData)).then(data => {
      console.log('response from EditReport ===>', data)
    })
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Report
          </Typography>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, isSubmitting }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="User Name"
                      margin="normal"
                      name="userName"
                      value={values.userName}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="User Email"
                      margin="normal"
                      name="userEmail"
                      value={values.userEmail}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth disabled>
                      <DropdownMenu
                        value={values.issueType}
                        label="IssueType"
                        name="issueType"
                        data={IssueTypeList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Report Location"
                      margin="normal"
                      name="reportLocation"
                      value={values.reportLocation}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Exercise Detail Url"
                      margin="normal"
                      name="exerciseDetailUrl"
                      value={values.exerciseDetailUrl}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Vocabulary Url"
                      margin="normal"
                      name="vocabularyUrl"
                      value={values.vocabularyUrl}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Exercise Master Url"
                      margin="normal"
                      name="exerciseMasterUrl"
                      value={values.exerciseMasterUrl}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <Typography color="textPrimary" variant="h6" pb={2}>
                        <span style={{ color: '#6b778c' }}>User Comments</span>
                        <br></br>
                        {values.comments}
                      </Typography>
                    </FormControl>
                  </Grid>                  
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendEmail}
                          onChange={e => {
                            values.sendUpdatetoUser = e.currentTarget.checked
                            setSendEmail(e.currentTarget.checked)
                          }}
                        />
                      }
                      label="Send Update to user?"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.status}
                        label="Status"
                        name="status"
                        isRequired={true}
                        onChange={handleChange}
                        data={IssueTypeStatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <Typography color="textPrimary" variant="h6">
                        <span style={{ color: '#6b778c' }}>Admin Comments</span>
                      </Typography>
                      <TextareaAutosize
                        style={{ width: '100%', height: 100 }}
                        maxRows={6}
                        label="Admin Comments"
                        margin="normal"
                        name="Comments"
                        placeholder="Please enter comments"
                        onChange={e => {
                          values.adminComments = e.currentTarget.value
                        }}
                        defaultValue={values?.adminComments}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoadingButton
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      name="Save"
                    ></LoadingButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddReport
