import { Box, Container, Drawer } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import AddEditCourse from 'src/components/course/AddEditCourse'
import AddButton from 'src/components/helperComponents/AddButton'
import DataTableNew from 'src/components/helperComponents/DataTable/DataTableNew'
import DialogComponent from 'src/components/helperComponents/DialogComponent'
import PlayerComponent from 'src/components/helperComponents/PlayerComponent'
import useQueryTable from 'src/hooks/useQueryTable'
import { DeleteCourseById, GetCourses } from 'src/redux/actions'
import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import { GetCourseTableColumns } from 'src/util/columns'

const CourseList = () => {
  const dispatch = useDispatch()

  const { courses } = useSelector(store => store.mainReducer)

  const [recordToEdit, setRecordToEdit] = useState()
  const [recordToDelete, setRecordToDelete] = useState()
  const [mediaUrl, setMediaUrl] = useState()
  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data, isLoading, isFetching, refetch } = useQueryTable({
    name: 'courses',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
    // additionalFilters: customFilterQuery,
  })

  useEffect(() => {
    dispatch(GetCourses())
  }, [])

  const onDeleteRow = async () => {
    if (recordToDelete) {
      await dispatch(DeleteCourseById(recordToDelete))
      refetch()
      setRecordToDelete()
    }
  }

  const columns = useMemo(() => GetCourseTableColumns(), [])

  return (
    <>
      <Helmet>
        <title>Courses | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton type="Language" onClick={() => setRecordToEdit({})} />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 500,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditCourse
              selectedRow={recordToEdit}
              refetch={refetch}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              onEditClick={setRecordToEdit}
              onDeleteClick={setRecordToDelete}
              data={data}
              isLoading={isLoading}
              isFetching={isFetching}
              name="courses"
              setPagination={setPagination}
              // additionalFilters={customFilterQuery}
            />
            {/* <DataTableNew
              data={courses}
              columns={GetCourseTableColumns(
                setMediaUrl,
                setRecordToEdit,
                setRecordToDelete
              )}
              apiName="courses"
            /> */}
          </Box>
          {mediaUrl && (
            <PlayerComponent
              url={mediaUrl}
              onDialogCancel={() => setMediaUrl()}
            />
          )}
          {recordToDelete && (
            <DialogComponent
              title="Delete Course?"
              description="All related units, chapters, lessons, exercise masters will also be auto removed. Still want to delete?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default CourseList
