/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import * as React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import { sortedByOrder } from 'src/util/helpers'

import API from '../../../util/api'
import Config from '../../../util/config'
import CustomNoRowsOverlay from './CustomNoRowsOverlay'
import QuickSearchToolbar from './QuickSearchToolbar'

const DataTableNew = ({
  columns,
  apiName,
  checkboxSelection,
  onSelectionChange,
  filters,
  preselectedRows = [],
  customFilterQuery,
}) => {
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 50,
    rows: [],
    totalRows: 0,
    loading: false,
    queryFilter: {},
  })

  const [allSelectedRows, setSelectedRows] = React.useState([])
  const [selectionModel, setSelectionModel] = React.useState([])
  const prevSelectionModel = React.useRef({
    isRequestSent: false,
    requestNumber: 0,
    selectionModel,
  })

  //
  const dataIsStale = useSelector(
    state => state.mainReducer[apiName + 'StaleOn']
  )

  React.useEffect(() => {
    loadGridData()
  }, [apiName, rowsState.page, rowsState.pageSize, dataIsStale])

  React.useEffect(() => {
    setSelectionModel(preselectedRows.map(item => item.id))
    setSelectedRows(preselectedRows)
  }, [])

  const requestSearch = debounce(event => {
    const searchValue = event?.target?.value || ''
    loadGridData(searchValue)
  }, 500)

  async function loadGridData(textSearch) {
    setRowsState(prev => ({ ...prev, loading: true }))

    prevSelectionModel.current.selectionModel = selectionModel

    const searchText = textSearch ? '&searchText=' + textSearch : ''

    const filterQuery = filters ? '&' + new URLSearchParams(filters) : ''
    const difficultyLevelFilter =
      customFilterQuery && customFilterQuery.difficultyLevel
        ? '&difficultyLevel=' + customFilterQuery?.difficultyLevel
        : ''
    const unitFilter =
      customFilterQuery && customFilterQuery.unit
        ? '&unit=' + customFilterQuery?.unit
        : ''
    const courseFilter =
      customFilterQuery && customFilterQuery.course
        ? '&course=' + customFilterQuery?.course
        : ''
    const chapterFilter =
      customFilterQuery && customFilterQuery.chapter
        ? '&chapter=' + customFilterQuery?.chapter
        : ''
    const lessonFilter =
      customFilterQuery && customFilterQuery.lesson
        ? '&lesson=' + customFilterQuery?.lesson
        : ''
    let shareCustomFilter = ''
    if (
      difficultyLevelFilter !== '' ||
      courseFilter !== '' ||
      unitFilter !== '' ||
      chapterFilter !== '' ||
      lessonFilter !== ''
    ) {
      shareCustomFilter = `appliedCustomFilter=true${courseFilter}${difficultyLevelFilter}${unitFilter}${chapterFilter}${lessonFilter}`
    }
    const currentData = await API.get(
      `${Config.API_URI}${apiName}?page=${rowsState.page}${filterQuery}${searchText}&${shareCustomFilter}&pageSize=${rowsState.pageSize}`
    )

    prevSelectionModel.current.requestNumber += 1
    prevSelectionModel.current.isRequestSent = true
    setRowsState(prev => ({
      ...prev,
      loading: false,
      rows: sortedByOrder(currentData?.docs || currentData ),
      totalRows: currentData.totalDocs,
    }))

    setSelectionModel(prevSelectionModel.current.selectionModel)
  }

  const onSelectionModelChange = newSelectionModel => {
    if (
      prevSelectionModel.current.isRequestSent &&
      prevSelectionModel.current.requestNumber > 1
    ) {
      prevSelectionModel.current.isRequestSent = false
      return
    }
    if (onSelectionChange) {
      const combinedData = uniqBy(allSelectedRows.concat(rowsState.rows), 'id')
      const allExistingIds = allSelectedRows.map(item => item.id)
      const currentPagePreSelectedIds = rowsState.rows
        .filter(row => allExistingIds.includes(row.id))
        .map(item => item.id)

      const newAddedSelectedIds = newSelectionModel.filter(
        x => !currentPagePreSelectedIds.includes(x)
      )
      const removedIds = currentPagePreSelectedIds.filter(
        x => !newSelectionModel.includes(x)
      )
      const remainingIds = allExistingIds.filter(
        key => !removedIds.includes(key)
      )
      const finalData = uniqBy(remainingIds.concat(newAddedSelectedIds))
      const fullRecordsFromIds = finalData.map(id =>
        combinedData.find(item => item.id === id)
      )
      setSelectedRows(fullRecordsFromIds)
      onSelectionChange(finalData, fullRecordsFromIds)
      setSelectionModel(finalData)
    }
  }

  const onPageChange = page => {
    prevSelectionModel.current.selectionModel = selectionModel
    setRowsState(prev => ({ ...prev, page }))
  }

  return (
    <Card>
      <PerfectScrollbar>
        <DataGrid
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          componentsProps={{
            toolbar: {
              onChange: event => requestSearch(event),
              clearSearch: () => requestSearch(''),
            },
          }}
          columns={columns}
          pagination
          rowCount={rowsState.totalRows}
          {...rowsState}
          paginationMode="server"
          onPageChange={onPageChange}
          onPageSizeChange={pageSize =>
            setRowsState(prev => ({ ...prev, pageSize }))
          }
          onRowSelected={GridRowSelectedParams => {
            console.log('row selected', GridRowSelectedParams)
          }}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={onSelectionModelChange}
          disableSelectionOnClick={!checkboxSelection}
          autoHeight
          selectionModel={selectionModel}
        />
      </PerfectScrollbar>
    </Card>
  )
}

export default DataTableNew
