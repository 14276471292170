/* eslint-disable react-hooks/exhaustive-deps */
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'

import { StyledEngineProvider, ThemeProvider } from '@material-ui/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useCallback, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import theme from 'src/assets/theme'
import ErrorNotification from 'src/components/helperComponents/ErrorNotification'
import routes from 'src/routes'
import API from 'src/util/api'

import GlobalStyles from './components/GlobalStyles'
import useToken from './hooks/useToken'
import localStorageService from './util/localStorageService'

const queryClient = new QueryClient()

const App = () => {
  const [token, setToken] = useToken()
  const content = useRoutes(routes(token))

  const verifyUser = useCallback(() => {
    API.post('refreshToken')
      .then(data => {
        setToken(data.token)
        localStorageService.setAuthInfo(data)
        // call refreshToken every 5 minutes to renew the authentication token.
        setTimeout(verifyUser, 60 * 1000 * 5)
      })
      .catch(() => localStorageService.setAuthInfo(null))
  }, [])

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {content}
          <ErrorNotification />
        </ThemeProvider>
      </StyledEngineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
