export const SIGN_IN_SUCCESSFUL = 'SIGN_IN_SUCCESSFUL'
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED'
export const SIGN_OUT_SUCCESSFUL = 'SIGN_OUT_SUCCESSFUL'
export const SIGN_UP_SUCCESSFUL = 'SIGN_UP_SUCCESSFUL'
export const USER_UPDATED = 'USER_UPDATED'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export const SET_COURSES = 'SET_COURSES'
export const SET_COURSES_STALE = 'SET_COURSES_STALE'
export const SET_UNITS = 'SET_UNITS'
export const SET_UNITS_STALE = 'SET_UNITS_STALE'
export const SET_CHAPTERS = 'SET_CHAPTERS'
export const SET_CHAPTERS_STALE = 'SET_CHAPTERS_STALE'

export const SET_LESSONS = 'SET_LESSONS'
export const SET_LESSONS_STALE = 'SET_LESSONS_STALE'

export const SET_ENTERTAINMENT_VIDEOS = 'SET_ENTERTAINMENT_VIDEOS'
export const SET_ENTERTAINMENT_VIDEOS_STALE = 'SET_ENTERTAINMENT_VIDEOS_STALE'
export const SET_ENTERTAINMENT_TRANSCRIPTIONS =
  'SET_ENTERTAINMENT_TRANSCRIPTIONS'
export const SET_ENTERTAINMENT_VOCABULARIES = 'SET_ENTERTAINMENT_VOCABULARIES'
export const SET_EXERCISE_DETAILS = 'SET_EXERCISE_DETAILS'
export const SET_EXERCISE_DETAILS_STALE = 'SET_EXERCISE_DETAILS_STALE'
export const SET_EXERCISE_MASTERS = 'SET_EXERCISE_MASTERS'
export const SET_EXERCISE_MASTERS_STALE = 'SET_EXERCISE_MASTERS_STALE'
export const SET_SERVICE_LOGS = 'SET_SERVICE_LOGS'
export const SET_DROPDOWNS = 'SET_DROPDOWNS'
export const SHOW_SPINNER = 'SHOW_SPINNER'
export const HIDE_SPINNER = 'HIDE_SPINNER'
export const SET_USERS = 'SET_USERS'
export const SET_REPORTS = 'SET_REPORTS'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_FEEDBACKS = 'SET_FEEDBACKS'
export const SET_BANNERS = 'SET_BANNERS'
export const SET_BANNERS_STALE = 'SET_BANNERS_STALE'
