import { FormHelperText,InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'

const DropdownMenu = ({
  data,
  label,
  name,
  value,
  onChange,
  isRequired,
  fullWidth,
  errorsMsg,
  defaultValue,
}) => (
  <>
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      labelId={name}
      id={name + label + '-select'}
      value={value}
      label={label}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      required={isRequired}
      fullWidth={fullWidth}
    >
      {data?.map(option => (
        <MenuItem
          key={option?.key || option?.label}
          value={option?.key || option?.label}
        >
          {option.value}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>{errorsMsg}</FormHelperText>
  </>
)

DropdownMenu.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
}

export default DropdownMenu
