import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddLesson } from 'src/redux/actions'
import { HIDE_ERROR } from 'src/redux/errorReducer'
import { uploadToS3 } from 'src/util/uploadToS3'
import * as Yup from 'yup'

import { StatusList } from '../../util/constants'
import { hrs, minutes } from '../../util/helpers'
import Feedback from '../exercise/FormTypes/Feedback'
import VideoThumbnailField from '../exercise/FormTypes/Fields/VideoThumbnailField'
import VideoUploaderField from '../exercise/FormTypes/Fields/VideoUploaderField'
import DropdownMenu from '../helperComponents/DropdownMenu'
import LoadingButton from '../helperComponents/LoadingButton'
import useCourseUnitChapterState from './useCourseUnitChapterState'
const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required('Required'),
  sortOrder: Yup.number().min(0),
  description: Yup.string().min(5, 'Minimum 5 letters').required('Required'),
})

const AddEditLesson = ({ selectedRow, toggleDrawer, refetch }) => {
  const { courseDifficultyLevel } = useSelector(store => store.mainReducer)
  const [videoErrorMsg, setVideoErrorMsg] = useState(
    'Please enter either video link or upload video.'
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showVideoError, setshowVideoError] = useState(false)
  const handleClose = () => {
    dispatch({ type: HIDE_ERROR })
  }

  const { values, ...formik } = useFormik({
    initialValues: {
      name: selectedRow?.name || '',
      description: selectedRow?.description || '',
      video: selectedRow?.videos?.[0]?.video || '',
      videoType: selectedRow?.videoType || '',
      coin: selectedRow?.coin || '',
      videoIframe: selectedRow?.videoIframe || '',
      course: selectedRow?.course?._id || '',
      unit: selectedRow?.unit?._id || '',
      chapter: selectedRow?.chapter?._id || '',
      sortOrder: selectedRow?.sortOrder || '',
      difficultyLevel:
        (selectedRow && selectedRow?.difficultyLevel) || 'All levels',
      isActive:
        selectedRow && selectedRow.isActive === undefined
          ? true
          : selectedRow && selectedRow?.isActive,
      videoThumbnail: selectedRow?.videoThumbnail || '',
      exerciseVideo: selectedRow?.exerciseVideo || '',
      hrs: selectedRow?.hrs || 0,
      minutes: selectedRow?.minutes || 0,
      seconds: selectedRow?.seconds || 0,
      isUnLock: selectedRow?.isUnLock || false,
      feedbackQuestion:
        (selectedRow?.lessonFeedback?.length &&
          selectedRow?.lessonFeedback[0]?.feedbackQuestion) ||
        '',
      rating:
        (selectedRow?.lessonFeedback?.length &&
          selectedRow?.lessonFeedback[0]?.rating) ||
        false,
      comment:
        (selectedRow?.lessonFeedback?.length &&
          selectedRow?.lessonFeedback[0]?.comment) ||
        false,
      feedbackPlaceholder:
        (selectedRow?.lessonFeedback?.length &&
          selectedRow?.lessonFeedback[0]?.feedbackPlaceholder) ||
        '',
      unPublished:
        selectedRow && selectedRow.unPublished === undefined
          ? false
          : selectedRow && selectedRow?.unPublished,
    },
    validationSchema,

    onSubmit: async values => {
      const reqData = selectedRow._id
        ? {
            ...values,
            lessonFeedback:
              values?.rating || values?.comment
                ? {
                    id:
                      (selectedRow?.lessonFeedback?.length &&
                        selectedRow?.lessonFeedback[0]?.id) ||
                      Date.now().toString(36) +
                        Math.random().toString(36).substr(2),
                    feedbackQuestion: values.feedbackQuestion,
                    rating: values.rating,
                    comment: values.comment,
                    feedbackPlaceholder: values.feedbackPlaceholder,
                  }
                : null,
            _id: selectedRow._id,
          }
        : {
            ...values,
            lessonFeedback:
              values?.rating || values?.comment
                ? {
                    id:
                      Date.now().toString(36) +
                      Math.random().toString(36).substr(2),
                    feedbackQuestion: values.feedbackQuestion,
                    rating: values.rating,
                    comment: values.comment,
                    feedbackPlaceholder: values.feedbackPlaceholder,
                  }
                : null,
          }
      delete reqData.rating
      delete reqData.comment
      delete reqData.feedbackPlaceholder
      delete reqData.feedbackQuestion
      for (let key in reqData) {
        if (reqData[key] instanceof File) {
          let file = reqData[key]
          await uploadToS3(file)
          reqData[key] = file.name
        }
      }
      reqData.hrs = reqData.hrs ? reqData.hrs : 0
      reqData.minutes = reqData.minutes ? reqData.minutes : 0
      reqData.seconds = reqData.seconds ? reqData.seconds : 0
      if (reqData.video.length === 0 && reqData.exerciseVideo.length === 0) {
        setshowVideoError(true)
      } else {
        setIsSubmitting(true)
        await dispatch(AddLesson(reqData))
        refetch();
        toggleDrawer(false)
      }
    },
  })

  const { chaptersList, coursesList, unitsList } =
    useCourseUnitChapterState(values)

  const dispatch = useDispatch()
  // const [totalUnits, setTotalUnits] = useState([])
  // const [totalCourses, setTotalCourses] = useState([])

  // useEffect(() => {
  //   setTotalUnits(dropdowns?.units)
  //   setTotalCourses(dropdowns?.courses)
  // }, [dropdowns])

  const onCourseChange = ({ target: { value } }) => {
    // setTotalUnits(filterByKeyValue(dropdowns?.units, 'course', value))
    formik.setValues({ ...values, chapter: '', unit: '', course: value })
  }

  const onUnitChange = ({ target: { value } }) => {
    // setTotalUnits(filterByKeyValue(dropdowns?.units, 'course', value))
    formik.setValues({ ...values, chapter: '', unit: value })
  }

  const handleCancel = () => {
    toggleDrawer(false)
  }
  const onStatusChange = ({ target: { value } }) => {
    formik.setValues({ ...values, chapter: '', isActive: value })
  }
  const onisUnLockChange = ({ target: { checked } }) => {
    formik.setValues({ ...values, chapter: '', isUnLock: checked })
  }
  const onThumbnailChange = ({ target: { value, files } }) => {
    formik.setValues({ ...values, videoThumbnail: files[0] })
  }

  const onVideoChange = e => {
    formik.setValues({ ...values, exerciseVideo: e.target.files[0] })
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Lesson
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <TextField
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={values.name}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  error={Boolean(
                    formik.touched.description && formik.errors.description
                  )}
                  fullWidth
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  label="Description"
                  margin="normal"
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={values.description}
                  variant="outlined"
                  required
                />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.course}
                  label="Language"
                  name="course"
                  onChange={onCourseChange}
                  isRequired={true}
                  data={coursesList}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.unit}
                  label="Course"
                  name="unit"
                  isRequired={true}
                  onChange={onUnitChange}
                  data={unitsList}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.chapter}
                  label="Chapter"
                  name="chapter"
                  isRequired={true}
                  onChange={formik.handleChange}
                  data={chaptersList}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.difficultyLevel}
                  label="Difficulty Level"
                  name="difficultyLevel"
                  onChange={formik.handleChange}
                  data={courseDifficultyLevel}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography style={{ font: 'inherit' }}>
                Duration of Lesson
              </Typography>
              <Stack p={2} flexDirection={'row'} rowGap={2}>
                <FormControlLabel
                  label={
                    <Typography style={{ padding: '10px' }}>Hrs</Typography>
                  }
                  control={
                    <DropdownMenu
                      value={values.hrs}
                      name="hrs"
                      style={{ paddingLeft: '20px' }}
                      onChange={formik.handleChange}
                      isRequired={true}
                      data={hrs}
                    />
                  }
                ></FormControlLabel>
                <FormControlLabel
                  label={
                    <Typography style={{ padding: '10px' }}>Minutes</Typography>
                  }
                  control={
                    <DropdownMenu
                      value={values.minutes}
                      name="minutes"
                      onChange={formik.handleChange}
                      isRequired={true}
                      data={minutes}
                    />
                  }
                ></FormControlLabel>
                <FormControlLabel
                  label={
                    <Typography style={{ padding: '10px' }}>Seconds</Typography>
                  }
                  control={
                    <DropdownMenu
                      value={values.seconds}
                      name="seconds"
                      onChange={formik.handleChange}
                      isRequired={true}
                      data={minutes}
                    />
                  }
                ></FormControlLabel>
              </Stack>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControlLabel
                paddingBottom={'10px'}
                control={
                  <Checkbox
                    checked={values.isUnLock}
                    onChange={onisUnLockChange}
                  />
                }
                label="Is Unlock"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.isActive}
                  label="Status"
                  name="isActive"
                  isRequired={true}
                  onChange={onStatusChange}
                  data={StatusList}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <VideoThumbnailField
                imageUrl={values?.videoThumbnail}
                onChange={onThumbnailChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={Boolean(formik.touched.video && formik.errors.video)}
                fullWidth
                helperText={formik.touched.video && formik.errors.video}
                label="Video URL"
                type={'url'}
                placeholder="Video Link"
                margin="normal"
                name="video"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={values.video}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <VideoUploaderField
                onChange={onVideoChange}
                // isDisabled={isDisabled}
                src={values?.exerciseVideo}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                label={'Normal Video'}
                control={
                  <Radio
                    name="videoType"
                    onChange={formik.handleChange}
                    checked={values.videoType === 'NORMAL'}
                    value={'NORMAL'}
                  />
                }
                htmlFor="videoType"
              ></FormControlLabel>
              <FormControlLabel
                label={'4L Video'}
                control={
                  <Radio
                    name="videoType"
                    onChange={formik.handleChange}
                    checked={values.videoType === '4L'}
                    value={'4L'}
                  />
                }
                htmlFor="videoType"
              ></FormControlLabel>
            </Grid>
            <Grid mb={2} item md={12} xs={12}>
              <Feedback values={values} onChange={formik.handleChange} />
            </Grid>
            <Grid item mb={2} md={12} xs={12}>
              <TextField
                fullWidth
                label="Video Iframe"
                multiline
                rows={4}
                placeholder="Video Iframe"
                name="videoIframe"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={values.videoIframe}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={Boolean(formik.touched.coin && formik.errors.coin)}
                fullWidth
                helperText={formik.touched.coin && formik.errors.coin}
                label="Video Coin"
                placeholder="Video Coin"
                name="coin"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={values.coin}
                variant="outlined"
                type="number"
              />

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Sort Order"
                  margin="normal"
                  inputProps={{ min: 0 }}
                  name="sortOrder"
                  type="number"
                  defaultValue={0}
                  onChange={formik.handleChange}
                  value={values?.sortOrder}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <DropdownMenu
                    value={values.unPublished}
                    label="Unpublished"
                    name="unPublished"
                    isRequired={true}
                    onChange={formik.handleChange}
                    data={StatusList}
                  />
                </FormControl>
              </Grid>
              {showVideoError && (
                <Stack gap={2}>
                  <Box color={'red'} padding={'10px'}>
                    {videoErrorMsg}
                  </Box>
                </Stack>
              )}
              <Grid mb={2} item md={12} xs={12}>
                <LoadingButton
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  name="Save"
                ></LoadingButton>
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  color="primary"
                  onClick={handleCancel}
                  // disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="button"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </>
  )
}

export default AddEditLesson
