import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core'
import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useUser from 'src/hooks/useUser'
import { UpdateUserProfile } from 'src/redux/actions'

import Dropdown from '../helperComponents/Dropdown'

const AccountProfileDetails = props => {
  const dispatch = useDispatch()
  const { data: user } = useUser()
  const {
    _id,
    surName,
    firstName,
    lastName,
    email,
    dob,
    country,
    state,
    city,
  } = user
  const countries = useSelector(store => store.mainReducer.countries)
  const states = useSelector(store => store.mainReducer.states)
  const surnames = useSelector(store => store.mainReducer.surnames)
  const [values, setValues] = useState({
    _id,
    surName,
    firstName,
    lastName,
    email,
    dob: moment(dob).format('DD/MM/YYYY'),
    country,
    state,
    city,
  })

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <form autoComplete="off" noValidate {...props}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Dropdown
                data={surnames}
                label="Select Surname"
                name="surName"
                value={values.surName}
                onChange={handleChange}
                isRequired
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                //helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Date of birth"
                name="dob"
                InputLabelProps={{
                  shrink: true,
                  required: true,
                }}
                onChange={handleChange}
                type="date"
                value={values.dob}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Dropdown
                data={countries}
                label="Select Country"
                name="country"
                value={values.country}
                onChange={handleChange}
                isRequired
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Dropdown
                data={states}
                label="Select State"
                name="state"
                value={values.state}
                onChange={handleChange}
                isRequired
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => dispatch(UpdateUserProfile(values))}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  )
}

export default AccountProfileDetails
