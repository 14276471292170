import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import DropdownMenu from 'src/components/helperComponents/DropdownMenu'
import LoadingButton from 'src/components/helperComponents/LoadingButton'
import { EditFeedback } from 'src/redux/actions'
import { FeedbackStatusList } from 'src/util/constants'

const EditFeedbackForm = ({ selectedRow, toggleDrawer }) => {
  const initialValues = {
    rating: (selectedRow && selectedRow?.rating) || '',
    comment: (selectedRow && selectedRow?.comment) || '',
    status: (selectedRow && selectedRow?.status) || '',
  }
  const dispatch = useDispatch()

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = values => {
    const reqData = selectedRow._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    toggleDrawer(false)
    dispatch(EditFeedback(reqData)).then(data => {
      console.log('response from EditFeedback ===>', data)
    })
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Feedback
          </Typography>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, isSubmitting }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Rating"
                      margin="normal"
                      name="rating"
                      value={values.rating}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  {values.comment && (
                    <Grid item md={12} xs={12}>
                      <FormControl fullWidth>
                        <Typography color="textPrimary" variant="h6" pb={2}>
                          <span style={{ color: '#6b778c' }}>User Comment</span>
                          <br></br>
                          {values.comment}
                        </Typography>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.status}
                        label="Status"
                        name="status"
                        isRequired={true}
                        onChange={handleChange}
                        data={FeedbackStatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoadingButton
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      name="Save"
                    ></LoadingButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default EditFeedbackForm
