import {
  FormGroup,
  FormControlLabel,
  Button,
  Input,
  TextField,
  Stack,
  Box,
  Divider,
  Grid,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DeleteIconComponent from 'src/components/helperComponents/DeleteIconComponent'
import { getFilePreview } from 'src/util/helpers'
// Note: This form covers 2 use cases "Listen and record" and "Listen and type"

const ListenAndTypeOrRecord = ({ onDataChange, childData, onMediaRemove }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(childData)
  }, [childData])

  const changeHandler = ({ target: { name, value, files } }, fieldLabel) => {
    let finalValues
    if (files) {
      let file = files[0]
      file.fieldLabel = fieldLabel
      finalValues = {
        ...values,
        [name]: file,
      }
    } else {
      finalValues = {
        ...values,
        [name]: value,
      }
    }
    setValues(finalValues)
    onDataChange(finalValues)
  }

  return (
    <Box sx={{ p: 2, border: '1px solid grey' }}>
      <FormGroup>
        <>
          <Stack direction="row" spacing={2} margin={1} key="audioStack">
            <Grid direction="row" container spacing={1}>
              <Grid item md={3} xs={3}>
                <FormControlLabel
                  label={''}
                  htmlFor={`audio`}
                  control={
                    <>
                      <Input
                        style={{ display: 'none' }}
                        onChange={event => changeHandler(event, 'Audio')}
                        name={`audio`}
                        inputProps={{
                          accept: 'audio/mp3',
                        }}
                        id={`audio`}
                        type="file"
                        disabled={!!values.audio}
                      />
                      <Button variant="contained" component="span">
                        {'Audio'}
                      </Button>
                    </>
                  }
                ></FormControlLabel>
              </Grid>
              <Grid item md={8} xs={8}>
                {getFilePreview(values[`audio`])}
              </Grid>
              <Grid item md={1} xs={1}>
                {values[`audio`] && (
                  <DeleteIconComponent
                    onClick={() => onMediaRemove('audio', values)}
                  />
                )}
              </Grid>
            </Grid>
          </Stack>
          <Divider key="audioDivider" />
          <Stack direction="row" spacing={2} margin={1} key="audioSlowStack">
            <Grid direction="row" container spacing={1}>
              <Grid item md={3} xs={3}>
                <FormControlLabel
                  label={''}
                  htmlFor={`audioSlow`}
                  control={
                    <>
                      <Input
                        style={{ display: 'none' }}
                        onChange={event => changeHandler(event, 'Slow_Audio')}
                        name={`audioSlow`}
                        inputProps={{
                          accept: 'audio/mp3',
                        }}
                        id={`audioSlow`}
                        type="file"
                        disabled={!!values.audioSlow}
                      />
                      <Button variant="contained" component="span">
                        {'Slow_Audio'}
                      </Button>
                    </>
                  }
                ></FormControlLabel>
              </Grid>
              <Grid item md={8} xs={8}>
                {getFilePreview(values[`audioSlow`])}
              </Grid>
              <Grid item md={1} xs={1}>
                {values[`audioSlow`] && (
                  <DeleteIconComponent
                    onClick={() => onMediaRemove('audioSlow', values)}
                  />
                )}
              </Grid>
            </Grid>
          </Stack>
          <Divider key="audioSlowDivider" />
        </>
        <Stack direction="row" spacing={2} margin={1}>
          <TextField
            fullWidth
            label="Answer"
            name={'answer'}
            onChange={changeHandler}
            required
            value={values.answer}
            variant="outlined"
          />
        </Stack>
      </FormGroup>
    </Box>
  )
}

export default ListenAndTypeOrRecord
