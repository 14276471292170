import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import ReactPlayer from 'react-player'

const PlayerComponent = ({ url, onDialogCancel }) => {
  if (!url) {
    return <></>
  }

  const isImage = url.match(/\.(png|jpg|jpeg)$/) || url.includes('images') // Second check can be removed when we move things to Prodcution
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={true}
      onClose={onDialogCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {!isImage && <ReactPlayer width="100%" url={url} />}
        {isImage && (
          <img
            src={url}
            alt={'Preview image'}
            loading="lazy"
            width="100%"
            height="70%"
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default PlayerComponent
