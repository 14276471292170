import { Helmet } from 'react-helmet'
import { Box, Container, Drawer } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import AddEditVideo from 'src/components/Videos/AddEditVideo'
import { DeleteVideoById } from 'src/redux/actions'

import DataTableNew from 'src/components/helperComponents/DataTable/DataTableNew'
import { GetLessonVideoTableColumns } from 'src/util/columns'
import AddButton from 'src/components/helperComponents/AddButton'
import PlayerComponent from 'src/components/helperComponents/PlayerComponent'
import DialogComponent from 'src/components/helperComponents/DialogComponent'

const EntertainmentVideoList = () => {
  const dispatch = useDispatch()

  const entertainmentVideos = useSelector(
    store => store.mainReducer.entertainmentVideos
  )

  const [recordToEdit, setRecordToEdit] = useState()
  const [recordToDelete, setRecordToDelete] = useState()
  const [mediaUrl, setMediaUrl] = useState()

  // useEffect(() => {
  //   dispatch(GetEntertainmentVideos())
  // }, [])

  const onDeleteRow = () => {
    if (recordToDelete) {
      dispatch(DeleteVideoById(recordToDelete))
      setRecordToDelete()
    }
  }

  return (
    <>
      <Helmet>
        <title>Entertainment Video | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            type="Entertainment Video"
            onClick={() => setRecordToEdit({})}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 550,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditVideo
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <DataTableNew
              columns={GetLessonVideoTableColumns(
                setMediaUrl,
                setRecordToEdit,
                setRecordToDelete
              )}
              apiName="videos"
              data={entertainmentVideos}
            />
          </Box>
          {mediaUrl && (
            <PlayerComponent
              url={mediaUrl}
              onDialogCancel={() => setMediaUrl()}
            />
          )}
          {recordToDelete && (
            <DialogComponent
              title="Delete Entertainment Video?"
              description="Deleted entertainment video can not be recovered. Still want to delete?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default EntertainmentVideoList
