import { Box, Container, Drawer } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import AddButton from 'src/components/helperComponents/AddButton'
import useQueryTable from 'src/hooks/useQueryTable'
import { DeleteBannerById } from 'src/redux/actions'
import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import { statusRenderer } from 'src/util/columnRenders'

import AddEditBannerList from '../components/banner/AddEditBanner'

const BannerList = () => {
  const dispatch = useDispatch()
  const [recordToEdit, setRecordToEdit] = useState()
  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data, isLoading, isFetching, refetch } = useQueryTable({
    name: 'banners',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
  })

  const onDeleteRow = async record => {
    await dispatch(DeleteBannerById(record))
    refetch()
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'heading',
        header: 'Heading',
        width: 150,
      },
      {
        accessorKey: 'subtext',
        header: 'Sub text',
        width: 150,
      },
      {
        accessorKey: 'sortOrder',
        header: 'Sort Order',
        width: 200,
      },
      {
        accessorKey: 'isActive',
        header: 'Status',
        width: 150,
        accessorFn: statusRenderer,
      },
    ],
    []
  )

  return (
    <>
      <Helmet>
        <title>Banners | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton type="Banner" onClick={() => setRecordToEdit({})} />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 450,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditBannerList
              selectedRow={recordToEdit}
              refetch={refetch}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              name="banners"
              onEditClick={setRecordToEdit}
              onDeleteClick={onDeleteRow}
              data={data}
              isLoading={isLoading}
              isFetching={isFetching}
              setPagination={setPagination}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default BannerList
