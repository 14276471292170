import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
import Dropdown from 'src/components/helperComponents/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { SignUp } from 'src/redux/actions'
import DropdownMenu from 'src/components/helperComponents/DropdownMenu'

const Register = () => {
  const dispatch = useDispatch()
  const countries = useSelector(store => store.mainReducer.countries)
  const states = useSelector(store => store.mainReducer.states)
  const surnames = useSelector(store => store.mainReducer.surnames)

  return (
    <>
      <Helmet>
        <title>Register | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              surName: 'Mr.',
              firstName: '',
              lastName: '',
              email: '',
              dob: '',
              password: '',
              country: 'india',
              state: 'up',
              city: '',
              policy: false,
              role: 'INSTRUCTOR',
            }}
            validationSchema={Yup.object().shape({
              surName: Yup.string()
                .min(2)
                .max(30)
                .required('Surname is required'),
              firstName: Yup.string()
                .min(3)
                .max(30)
                .required('First name is required'),
              lastName: Yup.string()
                .min(3)
                .max(30)
                .required('Last name is required'),
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              dob: Yup.date().required('Date of birth is required'),
              password: Yup.string()
                .min(5)
                .max(30)
                .required('Password is required'),
              country: Yup.string().max(30).required('Country is required'),
              state: Yup.string().max(30).required('State is required'),
              city: Yup.string().max(30).required('City is required'),
              policy: Yup.boolean().oneOf([true], 'This field must be checked'),
            })}
            onSubmit={values => dispatch(SignUp(values))}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Create new account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <DropdownMenu
                      data={surnames}
                      label="Select Surname"
                      name="surName"
                      value={values.surName}
                      onChange={handleChange}
                      isRequired
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="First name"
                      margin="normal"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label="Last name"
                      margin="normal"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.dob && errors.dob)}
                      fullWidth
                      helperText={touched.dob && errors.dob}
                      label="Date of birth"
                      margin="normal"
                      name="dob"
                      type="date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dob}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Dropdown
                      data={countries}
                      label="Select Country"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      isRequired
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Dropdown
                      data={states}
                      label="Select State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isRequired
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label="City"
                      margin="normal"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      variant="outlined"
                      required
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1,
                  }}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography color="textSecondary" variant="body1">
                    I have read the{' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  Have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                    underline="hover"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default Register
