import { Checkbox, Link } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {
  BookOpen as BookOpenIcon,
  Edit as EditIcon,
  Mic as MicIcon,
  PenTool as PenToolIcon,
  Speaker as SpeakerIcon,
  Trash2 as DeleteIcon,
} from 'react-feather'

import { statusRenderer } from './columnRenders'
import {
  NotificationCategoryList,
  NotificationTagGroupList,
  NotificationTargetGroupList,
} from './constants'

const difficultyLevelMapper = {
  continueLearning: 'Continue Learning',
  absoluteBeginners: 'Absolute Beginners',
  beginner: 'Beginner',
  intermidiate: 'Intermediate',
  advanced: 'Advanced',
  fluent: 'Fluent',
  interestingStuff: 'Interesting Stuff',
  allLevels: 'All Levels',
}

const statusGetter = row => {
  return row?.isActive === false || row?.isActive === 'false'
    ? 'InActive'
    : 'Active'
}
const courseGetter = row => row.course?.name
const unitGetter = row => row.unit?.name
const chapterGetter = row => row.chapter?.name
const lessonGetter = row => row.lesson?.name
const createdAtGetter = row => moment(row?.createdAt).format('DD/MM/YYYY')
const difficultyLevelGetter = ({ row }) =>
  difficultyLevelMapper[row.difficultyLevel]
const createUserName = ({ row }) => `${row?.username}`
const dateTimeGetter = ({ row }) =>
  moment(row?.createdAt).format('DD/MM/YYYY HH:mm:ss A')
const lastLoginGetter = ({ row }) =>
  moment(row?.lastLogin).format('DD/MM/YYYY HH:mm:ss A')
const linkRenderer = (url, onClick) => (
  <Link onClick={() => onClick(url)}>Link</Link>
)
const capitalizeText = ({ row }) =>
  row['issueType'].charAt(0).toUpperCase() + row['issueType'].slice(1)

const notificationCategoryList = ({ row }) =>
  NotificationCategoryList.filter(item => item.key === row.category)[0]?.[
    'value'
  ]

const notificationTargetGroupList = ({ row }) =>
  NotificationTargetGroupList.filter(item => item.key === row.targetGroup)[0]?.[
    'value'
  ]
const notificationTagGroupListRender = ({ row }) =>
  NotificationTagGroupList.filter(item => item.key === row.tag)[0]?.['value']

const usernameRenderer = ({ row }) => row.user.name
const categoryIconRenderer = record => {
  let categoryIconToReturn
  switch (record.row.category) {
    case 'Listening':
      categoryIconToReturn = <MicIcon />
      break
    case 'Reading':
      categoryIconToReturn = <BookOpenIcon />
      break
    case 'Writing':
      categoryIconToReturn = <PenToolIcon />
      break
    case 'Speaking':
      categoryIconToReturn = <SpeakerIcon />
      break
    default:
      categoryIconToReturn = <BookOpenIcon />
      break
  }
  return categoryIconToReturn
}

const actionRenderer = (record, onEditRow, onDeleteRow) => (
  <>
    <Link onClick={() => onEditRow(record.row)}>
      <EditIcon />
    </Link>
    <Link onClick={() => onDeleteRow(record.id)}>
      <DeleteIcon />
    </Link>
  </>
)
const actionRendererWithoutDelete = (record, onEditRow) => (
  <>
    <Link onClick={() => onEditRow(record.row)}>
      <EditIcon />
    </Link>
  </>
)
const deleteRenderer = (record, onDeleteRow) => (
  <Link onClick={() => onDeleteRow(record.id)}>
    <DeleteIcon />
  </Link>
)

const GetCourseTableColumns = () => [
  {
    accessorKey: 'name',
    header: 'Language',
    width: 160,
  },
  // {
  //   field: 'description',
  //   headerName: 'Description',
  //   width: 200,
  // },
  // {
  //   field: 'category',
  //   headerName: 'Category',
  //   //type: "number",
  //   width: 150,
  //   //editable: true,
  // },
  {
    accessorKey: 'sortOrder',
    header: 'Sort Order',
    width: 200,
  },
  // {
  //   accessorKey: 'difficultyLevel',
  //   header: 'Difficulty Level',
  //   width: 180,
  //   valueGetter: difficultyLevelGetter
  // },
  // {
  //   accessorKey: 'videos',
  //   header: 'Video',
  //   width: 130,
  //   renderCell: cellValues => linkRenderer(cellValues.row.video, onMediaClick),
  // },
  // {
  //     accessorKey: "image",
  //     header: "Image",
  //     width: 130,
  //     renderCell: (cellValues) =>
  //         linkRenderer(cellValues.row.image, onMediaClick),
  // },
  // {
  //     accessorKey: "whatYouWillLearn",
  //     header: "What You Will Learn",
  //     width: 200,
  // },
  {
    accessorKey: 'isActiveX',
    header: 'Status',
    width: 130,
    accessorFn: statusGetter,
  },
  {
    accessorKey: 'createdAtX',
    header: 'Created On',
    width: 160,
    accessorFn: createdAtGetter,
  },
  // {
  //   accessorKey: 'Actions',
  //   width: 200,
  //   renderCell: cellValues =>
  //     actionRenderer(cellValues, onEditRow, onDeleteRow),
  // },
]

const GetUnitTableColumns = (onEditRow, onDeleteRow) => [
  {
    accessorKey: 'nameX',
    header: 'Language',
    width: 250,
    accessorFn: courseGetter,
  },
  {
    accessorKey: 'name',
    header: 'Course',
    width: 250,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    width: 300,
  },
  {
    accessorKey: 'sortOrder',
    header: 'Sort Order',
    width: 200,
  },
  {
    accessorKey: 'isActiveX',
    header: 'Status',
    width: 150,
    accessorFn: statusGetter,
  },
  {
    accessorKey: 'createdAtX',
    header: 'Created On',
    width: 180,
    accessorFn: createdAtGetter,
  },
]
const GetChapterTableColumns = () => [
  {
    accessorKey: 'nameX',
    header: 'Language',
    width: 250,
    accessorFn: courseGetter,
  },
  {
    accessorKey: 'nameXX',
    header: 'Course',
    width: 250,
    accessorFn: unitGetter,
  },
  {
    accessorKey: 'name',
    header: 'Chapter',
    width: 250,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    width: 300,
  },
  {
    accessorKey: 'sortOrder',
    header: 'Sort Order',
    width: 200,
  },
  {
    accessorKey: 'isActiveX',
    header: 'Status',
    width: 150,
    accessorFn: statusGetter,
  },
  {
    accessorKey: 'createdAtX',
    header: 'Created On',
    width: 180,
    accessorFn: createdAtGetter,
  },
]
const GetLessonTableColumns = (onMediaClick, onEditRow, onDeleteRow) => [
  // {
  //   accessorKey: 'Actions',
  //   width: 150,
  //   renderCell: cellValues =>
  //     actionRenderer(cellValues, onEditRow, onDeleteRow),
  // },
  {
    header: 'Language',
    width: 150,
    accessorFn: data => data.course?.name || '',
  },
  {
    header: 'Course',
    width: 150,
    accessorFn: data => data.unit?.name || '',
  },
  {
    header: 'Chapter',
    width: 150,
    accessorFn: data => data.chapter?.name || '',
  },
  {
    accessorKey: 'sortOrder',
    header: 'Sort Order',
    width: 200,
  },
  {
    accessorKey: 'name',
    header: 'Lesson',
    width: 250,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    width: 300,
  },
  {
    field: 'isActive',
    header: 'Status',
    width: 150,
    accessorFn: statusRenderer,
  },
  // {
  //   field: 'createdAt',
  //   header: 'Created On',
  //   width: 180,
  //   valueGetter: createdAtGetter,
  // },
]
const GetLessonVideoTableColumns = (onMediaClick, onEditRow, onDeleteRow) => [
  {
    field: 'nameX',
    headerName: 'Course',
    width: 150,
    valueGetter: courseGetter,
  },
  {
    field: 'nameXX',
    headerName: 'Unit',
    width: 150,
    valueGetter: unitGetter,
  },
  {
    field: 'nameXXX',
    headerName: 'Chapter',
    width: 150,
    valueGetter: chapterGetter,
  },
  {
    field: 'nameXXXX',
    headerName: 'Lesson',
    width: 150,
    valueGetter: lessonGetter,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'videoUrl',
    headerName: 'Url',
    width: 150,
    renderCell: cellValues => linkRenderer(cellValues.row.video, onMediaClick),
  },
  {
    field: 'coin',
    headerName: 'Coin',
    width: 150,
  },
  {
    field: 'isActiveX',
    headerName: 'Status',
    width: 150,
    valueGetter: statusGetter,
  },
  {
    field: 'createdAtX',
    headerName: 'Created On',
    width: 180,
    valueGetter: createdAtGetter,
  },
  {
    field: 'Actions',
    width: 200,
    renderCell: cellValues =>
      actionRenderer(cellValues, onEditRow, onDeleteRow),
  },
]
const GetLessonVocabularyTableColumns = (
  onMediaClick,
  onEditRow,
  onDeleteRow
) => [
  {
    field: 'nameX',
    headerName: 'Course',
    width: 150,
    valueGetter: courseGetter,
  },
  {
    field: 'nameXX',
    headerName: 'Unit',
    width: 150,
    valueGetter: unitGetter,
  },
  {
    field: 'nameXXX',
    headerName: 'Chapter',
    width: 150,
    valueGetter: chapterGetter,
  },
  {
    field: 'nameXXXX',
    headerName: 'Lesson',
    width: 150,
    valueGetter: lessonGetter,
  },
  {
    field: 'wordInNative',
    headerName: 'Native Word',
    width: 200,
  },
  {
    field: 'wordInRoman',
    headerName: 'Roman Word',
    width: 200,
  },
  {
    field: 'wordMeaningToDisplay',
    headerName: 'Word Meaning',
    width: 200,
  },
  {
    field: 'videoUrl',
    headerName: 'Url',
    width: 110,
    renderCell: cellValues => linkRenderer(cellValues.row.video, onMediaClick),
  },
  {
    field: 'coin',
    headerName: 'Coin',
    width: 120,
  },
  {
    field: 'isActiveX',
    headerName: 'Status',
    width: 150,
    valueGetter: statusGetter,
  },
  {
    field: 'createdAtX',
    headerName: 'Created On',
    width: 150,
    valueGetter: createdAtGetter,
  },
  {
    field: 'Actions',
    width: 150,
    renderCell: cellValues =>
      actionRenderer(cellValues, onEditRow, onDeleteRow),
  },
]

const GetExerciseMasterTableColumns = () => [
  {
    accessorKey: 'nameX',
    header: 'Language',
    width: 150,
    accessorFn: courseGetter,
  },
  {
    accessorKey: 'nameXX',
    header: 'Course',
    width: 150,
    accessorFn: unitGetter,
  },
  {
    accessorKey: 'nameXXX',
    header: 'Chapter',
    width: 150,
    accessorFn: chapterGetter,
  },
  {
    accessorKey: 'nameXXXX',
    header: 'Lesson',
    width: 250,
    accessorFn: lessonGetter,
  },
  {
    accessorKey: 'name',
    header: 'Exercise',
    width: 250,
  },
  {
    accessorKey: 'isActiveX',
    header: 'Status',
    width: 150,
    accessorFn: statusGetter,
  },
  {
    accessorKey: 'createdAtX',
    header: 'Created On',
    width: 180,
    accessorFn: createdAtGetter,
  },
]

const GetExerciseDetailTableColumns = (onEditRow, onDeleteRow) => [
  {
    field: 'Actions',
    width: 150,
    renderCell: cellValues =>
      actionRenderer(cellValues, onEditRow, onDeleteRow),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'questionType',
    headerName: 'Type',
    width: 250,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    renderCell: cellValues => categoryIconRenderer(cellValues),
  },
  {
    field: 'question',
    headerName: 'Question',
    width: 250,
  },
  {
    field: 'isActiveX',
    headerName: 'Status',
    width: 150,
    valueGetter: statusGetter,
  },
  {
    field: 'createdAtX',
    headerName: 'Created On',
    width: 180,
    valueGetter: createdAtGetter,
  },
]

function IndeterminateCheckbox({ indeterminate, className = '', ...rest }) {
  const ref = React.useRef(!null)
  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <Checkbox ref={ref} className={className + ' cursor-pointer'} {...rest} />
  )
}

const GetExerciseDetailSubSetColumns = () => [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    width: 50,
    cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  {
    accessorKey: 'name',
    header: 'Exercise Name',
    width: 200,
  },
  {
    accessorKey: 'question',
    header: 'Question',
    width: 200,
  },
]

const GetServiceLogColumns = onDeleteRow => [
  {
    field: 'message',
    headerName: 'Error Detail',
    width: 800,
  },
  {
    field: 'errorOrigin',
    headerName: 'Error Origin',
    width: 180,
  },
  {
    field: 'createdAtX',
    headerName: 'Created On',
    width: 180,
    valueGetter: dateTimeGetter,
  },
  {
    field: 'Actions',
    width: 150,
    renderCell: cellValues => deleteRenderer(cellValues, onDeleteRow),
  },
]

const GetUserTableColumns = (onEditRow, onDeleteRow) => [
  {
    field: 'userName',
    headerName: 'Name',
    width: 200,
    valueGetter: createUserName,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 150,
  },
  {
    field: 'isActive',
    headerName: 'Status',
    width: 150,
    valueGetter: statusGetter,
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    width: 180,
    valueGetter: lastLoginGetter,
  },
  {
    field: 'lastVisitedPage',
    headerName: 'Last Visit',
    width: 150,
  },
  {
    field: 'createdAt',
    headerName: 'Created On',
    width: 180,
    valueGetter: createdAtGetter,
  },
  {
    field: 'Actions',
    width: 200,
    renderCell: cellValues =>
      actionRendererWithoutDelete(cellValues, onEditRow, onDeleteRow),
  },
]
const GetReportsColumns = (onEditRow, onDeleteRow) => [
  {
    field: 'userName',
    headerName: 'User',
    width: 150,
  },
  {
    field: 'userEmail',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'reportLocation',
    headerName: 'Location',
    width: 100,
  },
  {
    field: 'issueType',
    headerName: 'Issue Type',
    width: 180,
    valueGetter: capitalizeText,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    valueGetter: capitalizeText,
  },
  {
    field: 'comments',
    headerName: 'Comments',
    width: 500,
  },
  {
    field: 'createdAt',
    headerName: 'Created On',
    width: 180,
    valueGetter: dateTimeGetter,
  },
  {
    field: 'Actions',
    width: 150,
    renderCell: cellValues => deleteRenderer(cellValues, onDeleteRow),
  },
  {
    field: 'Actions',
    width: 200,
    renderCell: cellValues =>
      actionRenderer(cellValues, onEditRow, onDeleteRow),
  },
]
const GetNotificationsColumns = (onEditRow, onDeleteRow) => [
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    valueGetter: notificationCategoryList,
  },
  {
    field: 'targetGroup',
    headerName: 'Target Group',
    width: 200,
    valueGetter: notificationTargetGroupList,
  },
  {
    field: 'tag',
    headerName: 'Tag Group',
    width: 200,
    valueGetter: notificationTagGroupListRender,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 180,
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 380,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    valueGetter: statusGetter,
  },

  {
    field: 'createdAt',
    headerName: 'Created On',
    width: 180,
    valueGetter: dateTimeGetter,
  },
  {
    field: 'Actions',
    width: 150,
    renderCell: cellValues => deleteRenderer(cellValues, onDeleteRow),
  },
  {
    field: 'Actions',
    width: 200,
    renderCell: cellValues =>
      actionRenderer(cellValues, onEditRow, onDeleteRow),
  },
]

const GetFeedbacksColumns = (onEditRow, onDeleteRow) => [
  {
    field: 'rating',
    headerName: 'Rating',
    width: 150,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 200,
  },
  {
    field: 'submissionDate',
    headerName: 'Submission Date',
    width: 180,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
  {
    field: 'user',
    headerName: 'Username',
    width: 150,
    valueGetter: usernameRenderer,
  },
  {
    field: 'Actions',
    width: 200,
    renderCell: cellValues =>
      actionRenderer(cellValues, onEditRow, onDeleteRow),
  },
]
export {
  GetChapterTableColumns,
  GetCourseTableColumns,
  GetExerciseDetailSubSetColumns,
  GetExerciseDetailTableColumns,
  GetExerciseMasterTableColumns,
  GetFeedbacksColumns,
  GetLessonTableColumns,
  GetLessonVideoTableColumns,
  GetLessonVocabularyTableColumns,
  GetNotificationsColumns,
  GetReportsColumns,
  GetServiceLogColumns,
  GetUnitTableColumns,
  GetUserTableColumns,
}
