import { useQuery } from '@tanstack/react-query'
import { fetchLoggedInUserInfo } from 'src/api'

export default function useUser() {
  const userData = useQuery(['me'], fetchLoggedInUserInfo, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  return userData
}
