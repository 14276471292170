import { Box, Button, Input } from '@material-ui/core'
import { useState } from 'react'
import { getFilePreview } from 'src/util/helpers'

function ImageField({ imageUrl, onChange }) {
  const [imageFile, setImageFile] = useState()

  const handleFileOnChange = event => {
    const file = event.target.files[0]

    if (file.size > 500000) {
      alert('Maximum file size can be 500KB')
      return
    }
    setImageFile(file)
    onChange(event)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 2,
      }}
    >
      <Box>
        {(imageFile || imageUrl) && getFilePreview(imageFile || imageUrl)}
      </Box>
      <label htmlFor="contained-button-image-file">
        <Input
          inputProps={{
            accept: 'image/*',
          }}
          id="contained-button-image-file"
          type="file"
          style={{ display: 'none' }}
          name="exerciseImage"
          onChange={handleFileOnChange}
        />
        <Button variant="contained" component="span">
          Upload Image
        </Button>
      </label>
    </Box>
  )
}

export default ImageField
