import TextField from '@mui/material/TextField'
import debounce from 'lodash/debounce'
import { useCallback } from 'react'

export default function FreeTextSearchInput({ onChange }) {
  const debouncedOnChange = useCallback(
    debounce(value => {
      onChange(value)
    }, 500),
    []
  )

  return (
    <TextField
      placeholder="Type to search"
      fullWidth
      onChange={e => {
        debouncedOnChange(e.currentTarget.value)
      }}
    />
  )
}
