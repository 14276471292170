import * as React from 'react'
import PropTypes from 'prop-types'
import {
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { createTheme, IconButton, TextField } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import { Search as SearchIcon, X as ClearIcon } from 'react-feather'

const defaultTheme = createTheme()
const useStyles = makeStyles(
  theme =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        flex: 1,
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
)

const QuickSearchToolbar = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {!props.hideFilters && (
        <div>
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </div>
      )}
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        styles={{}}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{
                visibility: props.value ? 'visible' : 'hidden',
              }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default QuickSearchToolbar
