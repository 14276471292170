import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import DropdownMenu from 'src/components/helperComponents/DropdownMenu'
import useDropdowns from 'src/hooks/useDropdowns'

const FilterPanel = ({ dispatcher, setCustomFilterQuery }) => {
  const { dropdowns } = useDropdowns()
  const dispatch = useDispatch()
  const location = useLocation()
  const courseDifficultyLevel = useSelector(
    store => store.mainReducer.courseDifficultyLevel
  )
  const courseList = dropdowns?.courses
  const [unitsList, setUnitList] = useState([])
  const [chaptersList, setChaptersList] = useState([])
  const [lessonList, setLessonList] = useState([])
  const [difficultyLevelList, setDifficultyLevelList] = useState(
    courseDifficultyLevel
  )
  const [lesson, setLesson] = useState('')
  const [difficultyLevel, setDifficultyLevel] = useState('')
  const [course, setCourse] = useState('')
  const [unit, setUnit] = useState('')
  const [chapters, setChapter] = useState('')

  const onApplyFilter = () => {
    const query = {
      course: course,
      difficultyLevel: difficultyLevel === 'allLevels' ? '' : difficultyLevel,
      appliedCustomFilter: true,
      unit: unit === '-1' ? '' : unit,
      chapter: chapters === '-1' ? '' : chapters,
      lesson: lesson === '-1' ? '' : lesson,
    }
    setCustomFilterQuery(query)
    dispatch(dispatcher(query))
  }
  const notShowFilter =
    location.pathname.indexOf('languages') !== -1 ||
    location.pathname.indexOf('entertainmentVideos') !== -1 ||
    location.pathname.indexOf('entertainmentVocabularies') !== -1 ||
    location.pathname.indexOf('customers') !== -1 ||
    location.pathname.indexOf('notifications') !== -1 ||
    location.pathname.indexOf('exerciseDetails') !== -1 ||
    location.pathname.indexOf('banners') !== -1
  if (notShowFilter) {
    return null
  }

  const onLanguageChange = value => {
    setCourse(value)
    setDifficultyLevel('')
    setUnit('')
    setChapter('')
    setLesson('')
    setDifficultyLevelList(courseDifficultyLevel)
    let unitData = dropdowns?.units?.filter(item => item.course === value)
    setUnitList(unitData)
    let chaptersData = dropdowns?.chapters?.filter(
      item => item.course === value
    )
    setChaptersList(chaptersData)
    let lessonsData = dropdowns?.lessons?.filter(item => item.course === value)
    setLessonList(lessonsData)
  }

  const onDifficultyLevelChange = value => {
    setDifficultyLevel(value)
    setUnit('')
    setChapter('')
    setLesson('')
    let unitData = dropdowns?.units?.filter(
      item => item.course === course && item.difficultyLevel === value
    )
    setUnitList(unitData)
    let chaptersData = dropdowns?.chapters?.filter(
      item => item.course === course && item.difficultyLevel === value
    )
    setChaptersList(chaptersData)
    let lessonsData = dropdowns?.lessons?.filter(
      item => item.course === course && item.difficultyLevel === value
    )
    setLessonList(lessonsData)
  }
  const onUnitsChange = value => {
    setUnit(value)
    setChapter('')
    setLesson('')
    let chaptersData = dropdowns?.chapters?.filter(
      item => item.course === course && item.unit === value
    )
    if (difficultyLevel?.length > 0) {
      chaptersData = chaptersData?.filter(
        item => item.difficultyLevel === difficultyLevel
      )
    }
    setChaptersList(chaptersData)
    let lessonsData = dropdowns?.lessons?.filter(
      item => item.course === course && item.unit === value
    )
    if (difficultyLevel?.length > 0) {
      lessonsData = lessonsData?.filter(
        item => item.difficultyLevel === difficultyLevel
      )
    }
    setLessonList(lessonsData)
  }
  const onChapterChange = value => {
    setChapter(value)
    setLesson('')
    let lessonsData = dropdowns?.lessons?.filter(
      item => item.course === course && item.chapter === value
    )
    if (difficultyLevel?.length > 0) {
      lessonsData = lessonsData?.filter(
        item => item.difficultyLevel === difficultyLevel
      )
    }
    if (unit?.length > 0) {
      lessonsData = lessonsData?.filter(item => item.unit === unit)
    }
    setLessonList(lessonsData)
  }

  const onRest = () => {
    const query = {
      appliedCustomFilter: false,
    }
    setCourse('')
    setDifficultyLevelList([])
    setChaptersList([])
    setLessonList([])
    setUnitList([])
    setLesson('')
    setUnit('')
    setChapter('')
    setDifficultyLevel('')
    setCustomFilterQuery(query)
    dispatch(dispatcher(query))
  }

  return (
    <Grid
      md={12}
      xs={12}
      display="flex"
      gap={1}
      justifyContent={'center'}
      // width={'70%'}
      paddingRight={'10px'}
      alignItems={'center'}
    >
      <Grid md={4} xs={4} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={course}
            label="Language"
            onChange={e => {
              onLanguageChange(e.target.value)
            }}
          >
            {courseList &&
              courseList.map(cr => {
                return <MenuItem value={cr.key}>{cr.value}</MenuItem>
              })}
          </Select>
        </FormControl>
      </Grid>
      <Grid md={4} xs={4} item>
        <FormControl fullWidth>
          <DropdownMenu
            value={difficultyLevel}
            label="Difficulty Level"
            name="difficultyLevel"
            onChange={e => {
              onDifficultyLevelChange(e.target.value)
            }}
            data={difficultyLevelList}
          />
        </FormControl>
      </Grid>
      {location.pathname.indexOf('courses') === -1 && (
        <Grid md={4} xs={4} item>
          <FormControl fullWidth>
            <DropdownMenu
              value={unit}
              label="Course"
              name="Units"
              onChange={e => {
                onUnitsChange(e.target.value)
                // applyChapterFilter(course, difficultyLevel, unit, e.target.value)
              }}
              data={unitsList}
            />
          </FormControl>
        </Grid>
      )}{' '}
      {(location.pathname.indexOf('lessons') !== -1 ||
        location.pathname.indexOf('exerciseMasters') !== -1 ||
        location.pathname.indexOf('exerciseDetails') !== -1) && (
        <Grid md={4} xs={4} item>
          <FormControl fullWidth>
            <DropdownMenu
              value={chapters}
              label="Chapters"
              name="Chapters"
              onChange={e => {
                onChapterChange(e.target.value)
              }}
              data={chaptersList}
            />
          </FormControl>
        </Grid>
      )}
      {(location.pathname.indexOf('exerciseMasters') !== -1 ||
        location.pathname.indexOf('exerciseDetails') !== -1) && (
        <Grid md={4} xs={4} item>
          <FormControl fullWidth>
            <DropdownMenu
              value={lesson}
              label="Lesson"
              name="Lesson"
              onChange={e => {
                setLesson(e.target.value)
              }}
              data={lessonList}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item>
        <Button color="secondary" variant="contained" onClick={onApplyFilter}>
          Apply Filter
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={onRest} px={4}>
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}

export default FilterPanel
