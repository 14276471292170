import { IconButton } from '@material-ui/core'
import { Trash as TrashIcon } from 'react-feather'
import PropTypes from 'prop-types'

const DeleteIconComponent = props => (
  <IconButton edge="end" aria-label="delete" {...props}>
    <TrashIcon />
  </IconButton>
)

DeleteIconComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default DeleteIconComponent
