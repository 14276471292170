import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { EditNotification } from 'src/redux/actions'
import DropdownMenu from 'src/components/helperComponents/DropdownMenu'
import {
  NotificationCategoryList,
  StatusList,
  NotificationTargetGroupList,
  NotificationTagGroupList,
} from 'src/util/constants'
import LoadingButton from 'src/components/helperComponents/LoadingButton'
import { useState } from 'react'

const AddNotification = ({ selectedRow, toggleDrawer }) => {
  const initialValues = {
    category: (selectedRow && selectedRow?.category) || 'contentUpdate',
    targetGroup: (selectedRow && selectedRow?.targetGroup) || 'all_Members',
    isActive: (selectedRow && selectedRow?.isActive) || 'true',
    message: (selectedRow && selectedRow?.message) || '',
    title: (selectedRow && selectedRow?.title) || '',
    tag: (selectedRow && selectedRow?.tag) || 'beginners',
    sendUpdatetoUser: false,
  }
  const [sendEmail, setSendEmail] = useState(false)
  const dispatch = useDispatch()

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = values => {
    const reqData = selectedRow._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    toggleDrawer(false)
    dispatch(EditNotification(reqData)).then(data => {
      console.log('response from Notifications ===>', data)
    })
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Notification
          </Typography>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleChange, isSubmitting }) => (
              <Form>
                <Grid container spacing={1} style={{ paddingTop: '10px' }}>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.category}
                        label="Category"
                        name="category"
                        isRequired={true}
                        onChange={handleChange}
                        data={NotificationCategoryList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.targetGroup}
                        label="Target Group"
                        name="targetGroup"
                        isRequired={true}
                        onChange={handleChange}
                        data={NotificationTargetGroupList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.tag}
                        label="Tag Group"
                        name="tag"
                        isRequired={true}
                        onChange={handleChange}
                        data={NotificationTagGroupList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      margin="normal"
                      name="title"
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <Typography color="textPrimary" variant="h6">
                        <span style={{ color: '#6b778c' }}>Message *</span>
                      </Typography>
                      <TextareaAutosize
                        style={{ width: '100%', height: 100 }}
                        maxRows={6}
                        label="Message"
                        margin="normal"
                        name="message"
                        placeholder="Please enter message"
                        required
                        onChange={e => {
                          values.message = e.currentTarget.value
                        }}
                        defaultValue={values?.message}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendEmail}
                          onChange={e => {
                            values.sendUpdatetoUser = e.currentTarget.checked
                            setSendEmail(e.currentTarget.checked)
                          }}
                        />
                      }
                      label="Send also email?"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoadingButton
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      name="Submit"
                    ></LoadingButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddNotification
