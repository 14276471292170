import { Helmet } from 'react-helmet'
import { Box, Container } from '@material-ui/core'
import { useSelector } from 'react-redux'
import ListToolbar from 'src/components/helperComponents/ListToolbar'
//import EntertainmentTranscriptionListResults from "src/components/lesson/EntertainmentTranscriptionListResults";
import { useState } from 'react'
import _ from 'lodash'

const EntertainmentTranscriptionList = () => {
  const entertainmentTranscriptions = useSelector(
    store => store.mainReducer.entertainmentTranscriptions
  )
  const [entertainmentTranscriptionList, setEntertainmentTranscriptionList] =
    useState(entertainmentTranscriptions)

  const handleSearch = e => {
    const { value } = e.target
    let filteredData = value.length
      ? _.filter(entertainmentTranscriptions, function (obj) {
          return obj.name.toLowerCase().includes(value.toLowerCase())
        })
      : entertainmentTranscriptions
    setEntertainmentTranscriptionList(filteredData)
  }

  return (
    <>
      <Helmet>
        <title>Entertainment Transcriptions | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ListToolbar
            type="Entertainment Transcription"
            onSearchChange={handleSearch}
          />
          <Box sx={{ pt: 3 }}>
            {/* <EntertainmentTranscriptionListResults
                            entertainmentTranscriptions={
                                entertainmentTranscriptionList
                            }
                        /> */}
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default EntertainmentTranscriptionList
