import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'
import DropdownMenu from 'src/components/helperComponents/DropdownMenu'
import LoadingButton from 'src/components/helperComponents/LoadingButton'
import useDropdowns from 'src/hooks/useDropdowns'
import { SignUp, UpdateUserProfile } from 'src/redux/actions'
import { StatusList, SurNameList } from 'src/util/constants'
import * as Yup from 'yup'

const AddEditUser = ({ selectedRow, toggleDrawer }) => {
  const dispatch = useDispatch()
  const { dropdowns } = useDropdowns()

  let initialValues = {
    firstName: '',
    lastName: '',
    isActive: false,
    email: '',
    lastVisitedPage: '',
    city: '',
    state: '',
    country: '',
  }
  //   useEffect(() => {
  //     dispatch(GetCourses())
  //   },[])
  const enrollmentsKeys = []

  if (selectedRow) {
    initialValues = {
      firstName: selectedRow.firstName,
      lastName: selectedRow.lastName,
      email: selectedRow.email,
      city: selectedRow.city,
      state: selectedRow.state,
      country: selectedRow.country,
      surName: selectedRow.surName,
      lastLogin: selectedRow.lastLogin,
      lastVisitedPage: selectedRow.lastVisitedPage,
      sortOrder: (selectedRow && selectedRow?.sortOrder) || '',
      id: selectedRow.id,
      dob: selectedRow.dob,
      enrollments: selectedRow.enrollments || [],
      isActive:
        selectedRow && selectedRow.isActive === undefined
          ? true
          : selectedRow && selectedRow?.isActive,
      role: (selectedRow && selectedRow?.role) || '',
      plan: (selectedRow && selectedRow?.plan) || '',
      username: (selectedRow && selectedRow?.username) || '',
      toDate: (selectedRow && selectedRow?.toDate) || '',
      phone: (selectedRow && selectedRow?.phone) || '',
      paymentDate: (selectedRow && selectedRow?.paymentDate) || '',
      fromDate: (selectedRow && selectedRow?.fromDate) || '',
      published:
        selectedRow && selectedRow.published === undefined
          ? true
          : selectedRow && selectedRow?.published,
      amount: (selectedRow && selectedRow?.amount) || '',
      taxAmount: (selectedRow && selectedRow?.taxAmount) || '',
      discountAmount: (selectedRow && selectedRow?.discountAmount) || '',
      grossAmount: (selectedRow && selectedRow?.grossAmount) || '',
      paymentMethod: (selectedRow && selectedRow?.paymentMethod) || '',
      transactionId: (selectedRow && selectedRow?.transactionId) || '',
      membershipId: (selectedRow && selectedRow?.membershipId) || '',
      isStarUser:
        selectedRow && selectedRow.isStarUser === undefined
          ? false
          : selectedRow && selectedRow?.isStarUser,
    }
    initialValues.enrollments.forEach(element => {
      enrollmentsKeys.push(element.course)
    })
  }

  const [enrollmentsValue, setEnrollmentsValue] = useState(enrollmentsKeys)
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
    lastName: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
    city: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
    state: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
    country: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
  })

  const handleCancel = () => {
    toggleDrawer(false)
  }
  let isSendActiveInActiveUpdate = false
  const sendActiveInActiveUpdate = e => {
    isSendActiveInActiveUpdate = e.currentTarget.checked
  }
  let isSendEnrollementsUpdate = false

  const sendEnrollementsUpdate = e => {
    isSendEnrollementsUpdate = e.currentTarget.checked
  }
  const handleSubmit = values => {
    // same shape as initial values
    const reqData = selectedRow._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    toggleDrawer(false)

    if (!reqData.id) {
      delete reqData.id
    }
    let userEnrollments = []
    enrollmentsValue.forEach(item => {
      let addedItem = reqData.enrollments.filter(el => el.course === item)[0]
      userEnrollments.push({
        course: item,
        courseId: item,
        limitedAccess: addedItem?.limitedAccess || true,
        createdAt: addedItem?.createdAt || new Date(),
        expiredAt:
          addedItem?.expiredAt ||
          new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      })
    })
    reqData.enrollments = userEnrollments
    reqData.isSendActiveInActiveUpdate = isSendActiveInActiveUpdate
    reqData.isSendEnrollementsUpdate = isSendEnrollementsUpdate
    if (!reqData.id) {
      reqData.password = (Math.random() + 151).toString(36).substring(4)
      reqData.dob = new Date()
      dispatch(SignUp(reqData))
    } else {
      dispatch(UpdateUserProfile(reqData))
    }

    console.log('User Data', reqData)
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a User
          </Typography>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <Grid item md={12} xs={12}>
                      <FormControl fullWidth>
                        <DropdownMenu
                          value={values.surName}
                          label="SurName"
                          name="surName"
                          onChange={handleChange}
                          data={SurNameList}
                        />
                      </FormControl>
                    </Grid>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="First Name"
                      margin="normal"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.username && errors.username)}
                      fullWidth
                      helperText={touched.username && errors.username}
                      label="User Name"
                      margin="normal"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.username}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.role && errors.role)}
                      fullWidth
                      helperText={touched.role && errors.role}
                      label="Role"
                      margin="normal"
                      name="role"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.role}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label="Phone"
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.published}
                        label="Published"
                        name="published"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <InputLabel id="demo-simple-select-label">
                      To Date
                    </InputLabel>
                    <FormControl fullWidth>
                      <DatePicker
                        selected={
                          (values.toDate && new Date(values.toDate)) || null
                        }
                        onChange={val => {
                          setFieldValue('toDate', val)
                        }}
                        dateFormat="dd.MM.yyyy"
                        selectsEnd
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <InputLabel id="demo-simple-select-label">
                      From Date
                    </InputLabel>
                    <FormControl fullWidth>
                      <DatePicker
                        selected={
                          (values.fromDate && new Date(values.fromDate)) || null
                        }
                        onChange={val => {
                          setFieldValue('fromDate', val)
                        }}
                        dateFormat="dd.MM.yyyy"
                        selectsEnd
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <InputLabel id="demo-simple-select-label">
                      Payment Date
                    </InputLabel>
                    <FormControl fullWidth>
                      <DatePicker
                        selected={
                          (values.paymentDate &&
                            new Date(values.paymentDate)) ||
                          null
                        }
                        onChange={val => {
                          setFieldValue('paymentDate', val)
                        }}
                        dateFormat="dd.MM.yyyy"
                        selectsEnd
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.amount && errors.amount)}
                      fullWidth
                      helperText={touched.amount && errors.amount}
                      label="Amount"
                      margin="normal"
                      name="amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.amount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.taxAmount && errors.taxAmount)}
                      fullWidth
                      helperText={touched.taxAmount && errors.taxAmount}
                      label="Tax Amount"
                      margin="normal"
                      name="taxAmount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.taxAmount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.discountAmount && errors.discountAmount
                      )}
                      fullWidth
                      helperText={
                        touched.discountAmount && errors.discountAmount
                      }
                      label="Discount Amount"
                      margin="normal"
                      name="discountAmount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.discountAmount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.grossAmount && errors.grossAmount)}
                      fullWidth
                      helperText={touched.grossAmount && errors.grossAmount}
                      label="Gross Amount"
                      margin="normal"
                      name="grossAmount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.grossAmount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.paymentMethod && errors.paymentMethod
                      )}
                      fullWidth
                      helperText={touched.paymentMethod && errors.paymentMethod}
                      label="Payment Method"
                      margin="normal"
                      name="paymentMethod"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.paymentMethod}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.transactionId && errors.transactionId
                      )}
                      fullWidth
                      helperText={touched.transactionId && errors.transactionId}
                      label="Transaction Id"
                      margin="normal"
                      name="transactionId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.transactionId}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.membershipId && errors.membershipId
                      )}
                      fullWidth
                      helperText={touched.membershipId && errors.membershipId}
                      label="Membership Id"
                      margin="normal"
                      name="membershipId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.membershipId}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                      required
                      disabled={values.id}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label="City"
                      margin="normal"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.state && errors.state)}
                      fullWidth
                      helperText={touched.state && errors.state}
                      label="State"
                      margin="normal"
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.state}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      helperText={touched.country && errors.country}
                      label="Country"
                      margin="normal"
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Last Login"
                      margin="normal"
                      name="lastLogin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastLogin}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.lastVisitedPage && errors.lastVisitedPage
                      )}
                      fullWidth
                      helperText={
                        touched.lastVisitedPage && errors.lastVisitedPage
                      }
                      label="Last Visited Page"
                      margin="normal"
                      name="lastVisitedPage"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastVisitedPage}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      error={Boolean(touched.course && errors.course)}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-label">
                        Enrollments
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={enrollmentsValue}
                        label="Enrollments"
                        multiple={true}
                        name="enrollments"
                        onChange={e => {
                          setEnrollmentsValue(e.target.value)
                        }}
                      >
                        {dropdowns?.courses?.map(course => {
                          return (
                            <MenuItem value={course.key} keys={course.key}>
                              {course.value}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      <FormHelperText>
                        {touched.course && errors.course}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      paddingBottom={'10px'}
                      control={
                        <Checkbox
                          checked={values.sendEnrollementsUpdate}
                          onChange={sendEnrollementsUpdate}
                        />
                      }
                      label="Send Enrollements update to user?"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      paddingBottom={'10px'}
                      control={
                        <Checkbox
                          checked={values.sendActiveInActiveUpdate}
                          onChange={sendActiveInActiveUpdate}
                        />
                      }
                      label="Send status update to user?"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isStarUser}
                        label="Is Star"
                        name="isStarUser"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoadingButton
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      name="Save"
                    ></LoadingButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddEditUser
