import { Stack, Switch, Grid, Typography } from '@material-ui/core'

import ImageField from './ImageField'
import VideoUploaderField from './VideoUploaderField'

export default function WatchVideoOrImageField({
  isImage,
  onChange,
  value,
  isDisabled,
}) {
  return (
    <Stack>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Video</Grid>
          <Grid item>
            <Switch
              checked={!!isImage}
              onChange={onChange}
              name="isImage"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item>Image</Grid>
        </Grid>
      </Typography>
      {isImage ? (
        <ImageField onChange={onChange} imageUrl={value} />
      ) : (
        <VideoUploaderField
          onChange={onChange}
          isDisabled={isDisabled}
          src={value}
        />
      )}
    </Stack>
  )
}
