import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useState } from 'react'
import { addUpdateBanner } from 'src/api'
import { uploadToS3 } from 'src/util/uploadToS3'

import { FlexAlignment, StatusList, TextAlignment } from '../../util/constants'
import VideoThumbnailField from '../exercise/FormTypes/Fields/VideoThumbnailField'
import DropdownMenu from '../helperComponents/DropdownMenu'
import LoadingButton from '../helperComponents/LoadingButton'
import Toaster from '../helperComponents/Toaster'
import useCourseUnitChapterState from '../lesson/useCourseUnitChapterState'

const AddEditBanner = ({ selectedRow, toggleDrawer }) => {
  const queryClient = useQueryClient()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const mutation = useMutation({
    mutationFn: values => {
      setIsSubmitting(true)
      return addUpdateBanner(values)
    },
    onSuccess: () => {
      toggleDrawer()
      setIsSubmitting(false)
      queryClient.invalidateQueries('banners')
    },
    onError: error => {
      setIsSubmitting(false)
      setShowError(true)
      setErrorMessage(error.response.data.message)
    },
  })

  const initialValues = {
    heading: selectedRow?.heading || '',
    subtext: selectedRow?.subtext || '',
    course: selectedRow?.course || '',
    sortOrder: selectedRow?.sortOrder || '',
    isActive:
      selectedRow && selectedRow.isActive === undefined
        ? true
        : selectedRow && selectedRow?.isActive,
    buttonName: selectedRow?.buttonName || '',
    buttonClickUrl: selectedRow?.buttonClickUrl || '',
    videoUrl: selectedRow?.videoUrl || '',
    imageUrl: selectedRow?.imageUrl || '',
    mobileImageUrl: selectedRow?.mobileImageUrl || '',
    imageClickUrl: selectedRow?.imageClickUrl || '',
    isVideoURL: selectedRow?.isVideoURL || false,
    buttonWidth: selectedRow?.buttonWidth || 0,
    buttonPosition: selectedRow?.buttonPosition || 'center',
    textPosition: selectedRow?.textPosition || 'center',
    isPublished:
      selectedRow && selectedRow.isPublished ? selectedRow.isPublished : false,
  }

  const { values, ...formik } = useFormik({
    initialValues: initialValues,
    onSubmit: async values => {
      if (
        values.buttonClickUrl.indexOf('http') !== -1 ||
        values.buttonClickUrl.indexOf('www') !== -1
      ) {
        setErrorMessage('Only internal URLs of Langistan can be implemented')
        setShowError(true)
      } else {
        for (let key in values) {
          if (values[key] instanceof File) {
            let file = values[key]
            await uploadToS3(file)
            values[key] = file.name
          }
        }
        values.buttonName = values.buttonName.trim()
        setIsSubmitting(true)
        values._id = selectedRow._id
        mutation.mutate(values)
        toggleDrawer(false)
      }
    },
  })

  const { coursesList } = useCourseUnitChapterState(values)

  const onCourseChange = ({ target: { value } }) => {
    formik.setValues({ ...values, chapter: '', course: value })
  }

  const handleCancel = () => {
    toggleDrawer(false)
  }
  const onStatusChange = ({ target: { value } }) => {
    formik.setValues({ ...values, chapter: '', isActive: value })
  }
  const onAlignmentChange = ({ target: { value } }) => {
    formik.setValues({ ...values, buttonPosition: value })
  }
  const onTextAlignmentChange = ({ target: { value } }) => {
    formik.setValues({ ...values, textPosition: value })
  }
  const onThumbnailChange = ({ target: { value, files } }) => {
    formik.setValues({ ...values, imageUrl: files[0] })
  }
  const onMobileThumbnailChange = ({ target: { value, files } }) => {
    formik.setValues({ ...values, mobileImageUrl: files[0] })
  }
  const onToasterClose = () => {
    setShowError(false)
    setErrorMessage('')
  }
  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Banner
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched.heading && formik.errors.heading
                  )}
                  fullWidth
                  helperText={formik.touched.heading && formik.errors.heading}
                  label="Heading"
                  margin="normal"
                  name="heading"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={values.heading}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched.subtext && formik.errors.subtext
                  )}
                  fullWidth
                  helperText={formik.touched.subtext && formik.errors.subtext}
                  label="Sub Text"
                  margin="normal"
                  inputProps={{ maxLength: 70 }}
                  name="subtext"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={values.subtext}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item md={12} mt={2} mb={2} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.textPosition}
                  label="Text Alignment"
                  name="isActive"
                  isRequired={true}
                  onChange={onTextAlignmentChange}
                  data={TextAlignment}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.course}
                  label="Language"
                  name="course"
                  onChange={onCourseChange}
                  isRequired={true}
                  data={coursesList}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} mt={3} xs={12}>
              <VideoThumbnailField
                imageUrl={values?.imageUrl}
                onChange={onThumbnailChange}
                label={'Upload Website Banner'}
              />
            </Grid>
            <Grid item mt={3} md={12} xs={12}>
              <VideoThumbnailField
                imageUrl={values?.mobileImageUrl}
                onChange={onMobileThumbnailChange}
                label={'Upload Mobile Banner'}
                id="contained-button-mobile-file"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.videoUrl && formik.errors.videoUrl
                )}
                fullWidth
                helperText={formik.touched.videoUrl && formik.errors.videoUrl}
                label="Video URL"
                type={'url'}
                placeholder="Video Link"
                margin="normal"
                name="videoUrl"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={values.videoUrl}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.buttonName && formik.errors.buttonName
                )}
                fullWidth
                helperText={
                  formik.touched.buttonName && formik.errors.buttonName
                }
                label="Button Name"
                placeholder="Button Name"
                name="buttonName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={values.buttonName}
                variant="outlined"
              ></TextField>
            </Grid>
            <Grid item md={12} mt={2} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.buttonClickUrl && formik.errors.buttonClickUrl
                )}
                fullWidth
                helperText={
                  formik.touched.buttonClickUrl && formik.errors.buttonClickUrl
                }
                label="Click Url"
                placeholder="Click Url"
                name="buttonClickUrl"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={values.buttonClickUrl}
                variant="outlined"
              ></TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Button Width"
                margin="normal"
                inputProps={{ min: 0 }}
                name="buttonWidth"
                type="number"
                defaultValue={0}
                onChange={formik.handleChange}
                value={values?.buttonWidth}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} mt={2} mb={2} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.buttonPosition}
                  label="Button Alignment"
                  name="isActive"
                  isRequired={true}
                  onChange={onAlignmentChange}
                  data={FlexAlignment}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Sort Order"
                margin="normal"
                inputProps={{ min: 0 }}
                name="sortOrder"
                type="number"
                defaultValue={0}
                onChange={formik.handleChange}
                value={values?.sortOrder}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} mt={1} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.isPublished}
                  label="Is Published"
                  name="isPublished"
                  isRequired={true}
                  onChange={formik.handleChange}
                  data={StatusList}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} mt={2} mb={2} xs={12}>
              <FormControl fullWidth>
                <DropdownMenu
                  value={values.isActive}
                  label="Status"
                  name="isActive"
                  isRequired={true}
                  onChange={onStatusChange}
                  data={StatusList}
                />
              </FormControl>
            </Grid>

            <Grid mb={2} item md={12} xs={12}>
              <LoadingButton
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                name="Save"
              ></LoadingButton>
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                color="primary"
                onClick={handleCancel}
                // disabled={isSubmitting}
                fullWidth
                size="large"
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
          </form>
        </Container>
      </Box>
      {showError && (
        <Toaster msg={errorMessage} severity="error" onClose={onToasterClose} />
      )}
    </>
  )
}

export default AddEditBanner
