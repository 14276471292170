import { Box, Container, Drawer } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddButton from 'src/components/helperComponents/AddButton'
import useQuery from 'src/hooks/useQuery'
import useQueryTable from 'src/hooks/useQueryTable'
import { DeleteChapterById, GetChapters } from 'src/redux/actions'
import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import { GetChapterTableColumns } from 'src/util/columns'

import AddEditChapter from '../components/chapter/AddEditChapter'

const ChapterList = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const [recordToEdit, setRecordToEdit] = useState()
  const { chapters } = useSelector(store => store.mainReducer)
  const [customFilterQuery, setCustomFilterQuery] = useState()

  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data, isLoading, isFetching, refetch } = useQueryTable({
    name: 'chapters',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
    additionalFilters: customFilterQuery,
  })

  const onDeleteRow = async recordToDelete => {
    if (recordToDelete) {
      await dispatch(DeleteChapterById(recordToDelete))
      refetch()
    }
  }

  const columns = useMemo(() => GetChapterTableColumns(), [])

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            type="Chapter"
            dispatcher={GetChapters}
            onClick={() => setRecordToEdit({})}
            setCustomFilterQuery={setCustomFilterQuery}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 400,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditChapter
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
              refetch={refetch}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            {/* <DataTableNew
              data={chapters}
              columns={GetChapterTableColumns(
                setRecordToEdit,
                setRecordToDelete
              )}
              filters={query}
              apiName="chapters"
              customFilterQuery={customFilterQuery}
            /> */}
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              onEditClick={setRecordToEdit}
              onDeleteClick={onDeleteRow}
              additionalFilters={customFilterQuery}
              data={data}
              name="chapters"
              isLoading={isLoading}
              isFetching={isFetching}
              setPagination={setPagination}
            />
          </Box>
          {/* {recordToDelete && (
            <DialogComponent
              title="Delete Chapter?"
              description="All related lessons, exercise masters will also be auto removed. Still want to delete?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )} */}
        </Container>
      </Box>
    </>
  )
}

export default ChapterList
