import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const Dropdown = ({ data, label, name, value, onChange, isRequired }) => (
  <TextField
    fullWidth
    label={label}
    name={name}
    onChange={onChange}
    required={isRequired}
    select
    SelectProps={{ native: true }}
    value={value}
    variant="outlined"
  >
    {data?.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </TextField>
)

Dropdown.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
}

export default Dropdown
