import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { useFormikContext } from 'formik'

import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  IconButton,
  Toolbar,
  Typography,
  Slide,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import compact from 'lodash/compact'
import debounce from 'lodash/debounce'
import produce from 'immer'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function SubtitleEditor({
  open,
  handleClose,
  onSave,
  subtitle,
}) {
  const [formattedData, setFormattedData] = useState(subtitle)
  const [selectedRow, setSelectedRow] = useState(undefined)

  const { values } = useFormikContext()

  useEffect(() => {
    setFormattedData(subtitle)
  }, [subtitle])

  function onFileRead(event) {
    const content = event.target?.result?.toString()

    if (content) {
      const data = content.split('\n\n').filter(item => item && !!item.trim())
      transformData(data)
    }
  }

  function transformData(rowData) {
    const tData = rowData.map(value => {
      const [id, timePeriod, ...text] = value.split('\n')
      const [startTime, endTime] = timePeriod.split('-->')
      const words = compact(text.join('\n').split(' '))
      const blankWords = new Array(words.length).fill('')
      return {
        id,
        startTime: startTime.trim(),
        endTime: endTime.trim(),
        transcript: [words, blankWords, blankWords, blankWords],
        originalText: text.join(''),
      }
    })
    setFormattedData(tData)
  }

  function handleFileSelect(evt) {
    const files = evt.target.files // FileList object

    // use the 1st file from the list
    const f = files[0]

    const reader = new FileReader()

    // Closure to capture the file information.
    reader.onload = onFileRead

    // Read in the image file as a data URL.
    reader.readAsText(f)
  }

  function onCellEdit(val, row, col) {
    if (selectedRow) {
      const updatedRowData = produce(selectedRow, draft => {
        draft.transcript[row][col] = val
      })

      const updatedFormattedData = formattedData.map(fd =>
        fd.id === selectedRow.id ? updatedRowData : fd
      )
      setSelectedRow(updatedRowData)
      setFormattedData(updatedFormattedData)
    }
  }

  const onSaveClick = () => {
    onSave(formattedData, { ...values, subtitle: formattedData })
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            SubtitleEditor
          </Typography>
          <Button autoFocus color="inherit" onClick={onSaveClick}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <div>
        {!subtitle && (
          <form>
            <input
              type="file"
              accept="text/plains"
              onChange={handleFileSelect}
            />
          </form>
        )}
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack spacing={2} borderBottom={1} minHeight={'50vh'}>
            {formattedData?.map((item, index) => (
              <Card
                sx={{ minWidth: 275 }}
                key={item.id}
                onClick={() => setSelectedRow(item)}
              >
                <CardContent>
                  <Typography variant="h5" component="pre">
                    {item.originalText}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Stack>
          <Box borderBottom={1} minHeight={'40vh'} padding={2}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormRow
                transcript={selectedRow?.transcript[0]}
                onEdit={(val, col) => onCellEdit(val, 0, col)}
              />
              <FormRow
                transcript={selectedRow?.transcript[1]}
                onEdit={(val, col) => onCellEdit(val, 1, col)}
              />
              <FormRow
                transcript={selectedRow?.transcript[2]}
                onEdit={(val, col) => onCellEdit(val, 2, col)}
              />
              <Typography variant="h1" align="center" py="15px">
                {selectedRow?.originalText}
              </Typography>
              {/* <FormRow
                transcript={selectedRow?.transcript[3]}
                onEdit={(val, col) => onCellEdit(val, 3, col)}
              /> */}
            </Box>
          </Box>
        </Box>
      </div>
      <DialogActions>
        <Button onClick={handleClose}>Save changes</Button>
      </DialogActions>
    </Dialog>
  )
}

function FormRow({ transcript, onEdit }) {
  if (!transcript) return null

  const handleOnBlur = (event, col) => {
    onEdit?.(event.currentTarget.value, col)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: 1,
      }}
    >
      {transcript.map((word, index) => (
        <Item sx={{ flex: 1 }} key={`${word}-${index}`}>
          <TextField
            fullWidth
            defaultValue={word}
            onBlur={e => handleOnBlur(e, index)}
          />
        </Item>
      ))}
    </Box>
  )
}
