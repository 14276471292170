import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import {
  BarChart2 as BarChartIcon,
  Book as BookIcon,
  BookOpen as BookOpenIcon,
  Clipboard as ClipboardIcon,
  Flag as FlagIcon,
  MessageCircle as NotificationIcon,
  Tool as ToolIcon,
  User as UsersIcon,
  Video as VideoIcon,
} from 'react-feather'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import useUser from 'src/hooks/useUser'

import NavItem from './NavItem'

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
  },
  {
    href: '/app/languages',
    icon: BookIcon,
    title: 'Languages',
  },
  {
    href: '/app/courses',
    icon: BookOpenIcon,
    title: 'Courses',
  },
  {
    href: '/app/chapters',
    icon: BookOpenIcon,
    title: 'Chapters',
  },
  {
    href: '/app/lessons',
    icon: BookOpenIcon,
    title: 'Lessons',
  },
  {
    href: '/app/banners',
    icon: BookOpenIcon,
    title: 'Banners',
  },
  {
    href: '/app/entertainmentVideos',
    icon: VideoIcon,
    title: 'Videos',
  },
  {
    href: '/app/entertainmentVocabularies',
    icon: VideoIcon,
    title: 'Vocabularies',
  },
  {
    href: '/app/exerciseMasters',
    icon: ClipboardIcon,
    title: 'Exercise Masters',
  },
  {
    href: '/app/exerciseDetails',
    icon: ClipboardIcon,
    title: 'Exercise Details',
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Users',
  },
  {
    href: '/app/feedbacks',
    icon: ToolIcon,
    title: 'Feedbacks',
  },
  {
    href: '/app/servicelogs',
    icon: ToolIcon,
    title: 'Service Logs',
  },
  {
    href: '/app/reports',
    icon: FlagIcon,
    title: 'Reports',
  },
  {
    href: '/app/notifications',
    icon: NotificationIcon,
    title: 'Notification',
  },
  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products'
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account'
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
]

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const { data: user } = useUser()
  const location = useLocation()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={user?.image ?? '/static/images/avatars/avatar_5.png'}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {user?.firstName + ' ' + user?.lastName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user?.role}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
}

export default DashboardSidebar
