import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

const DialogComponent = ({
  title,
  description,
  onDialogOk,
  onDialogCancel,
  actionButtons = ['Cancel', 'Delete'],
  children,
}) => {
  return (
    <Dialog
      open={true}
      onClose={onDialogCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {description && (
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogCancel} autoFocus>
          {actionButtons[0]}
        </Button>
        <Button
          onClick={onDialogOk || onDialogCancel}
          color={actionButtons[1] === 'Delete' ? 'error' : undefined}
        >
          {actionButtons[1]}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogComponent
