import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
} from '@material-ui/core'
// Note: This form covers 2 use cases "Watch and record" and "Watch and type"

const Feedback = ({ values, onChange }) => {
  return (
    <Box sx={{ p: 2, border: '1px solid lightGray' }}>
      <FormGroup>
        <TextareaAutosize
          name="feedbackQuestion"
          onChange={onChange}
          value={values.feedbackQuestion}
          minRows={4}
          placeholder="Enter question here."
          style={{ width: '250px' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.rating}
              name="rating"
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Is rating section required ?"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={values.comment}
              name="comment"
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Is comment section required ?"
        />
      </FormGroup>
      {values?.comment && (
        <TextareaAutosize
          name="feedbackPlaceholder"
          onChange={onChange}
          value={values.feedbackPlaceholder}
          minRows={4}
          placeholder="Enter comments placeholder here."
          style={{ width: '250px' }}
        />
      )}
    </Box>
  )
}

export default Feedback
