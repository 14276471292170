import { Box, Container, Drawer } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import DataTableNew from 'src/components/helperComponents/DataTable/DataTableNew'
import DialogComponent from 'src/components/helperComponents/DialogComponent'
import { DeleteReportById, GetReports } from 'src/redux/actions'
import { GetReportsColumns } from 'src/util/columns'
import AddReport from 'src/components/AddReport'

const ReportList = () => {
  const dispatch = useDispatch()

  const [recordToEdit, setRecordToEdit] = useState()
  const [recordToDelete, setRecordToDelete] = useState()
  const { reports } = useSelector(store => store.mainReducer)

  const onDeleteRow = () => {
    if (recordToDelete) {
      dispatch(DeleteReportById(recordToDelete))
      setRecordToDelete()
    }
  }
 
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 400,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddReport
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <DataTableNew
              data={reports}
              columns={GetReportsColumns(
                setRecordToEdit,
                setRecordToDelete
              )}
              apiName="reports"
            />
          </Box>
          {recordToDelete && (
            <DialogComponent
              title="Delete Report"
              description="Do you want to delete the report?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default ReportList
