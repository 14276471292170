import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { AddCourse } from 'src/redux/actions'
import { getFilePreview } from 'src/util/helpers'
import { uploadToS3 } from 'src/util/uploadToS3'
import * as Yup from 'yup'

import { StatusList } from '../../util/constants'
import DropdownMenu from '../helperComponents/DropdownMenu'
import LoadingButton from '../helperComponents/LoadingButton'

const AddEditCourse = ({ selectedRow, toggleDrawer, refetch }) => {
  const dispatch = useDispatch()
  const { courseCategories, courseDifficultyLevel } = useSelector(
    store => store.mainReducer
  )

  let initialValues = {
    name: '',
    description: '',
    langIcon: '',
    video: '',
    category: 'Language',
    difficultyLevel: 'All levels',
    duration: 0,
  }

  if (selectedRow) {
    initialValues = {
      name: selectedRow.name,
      description: selectedRow.description,
      langIcon: selectedRow.langIcon,
      video: selectedRow.video,
      category: selectedRow.category,
      difficultyLevel: selectedRow.difficultyLevel,
      duration: selectedRow.duration,
      sortOrder: (selectedRow && selectedRow?.sortOrder) || '',
      id: selectedRow.id,
      isActive:
        selectedRow && selectedRow.isActive === undefined
          ? true
          : selectedRow && selectedRow?.isActive,
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Minimum 1 letters').required('Required'),
    // video: Yup.string().required('Please enter video url'),
    // duration: Yup.number().min(0.1),
    // description: Yup.string().min(5, 'Minimum 5 letters').required('Required'),
    sortOrder: Yup.number(),
  })

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const onChangeLanguageIcon = ({ target: { name, files } }, setFieldValue) => {
    if (files) {
      let file = files[0]
      file.fieldLabel = name
      setFieldValue('langIcon', file)
    }
  }

  const handleSubmit = async values => {
    // same shape as initial values
    const reqData = selectedRow._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    toggleDrawer(false)

    if (!reqData.id) {
      delete reqData.id
    }
    for (let key in reqData) {
      if (reqData[key] instanceof File) {
        let file = reqData[key]
        await uploadToS3(file)
        reqData[key] = file.name
      }
    }
    await dispatch(AddCourse(reqData))
    refetch();
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Language
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      required
                    />
                  </Grid>

                  {/* <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.video && errors.video)}
                      fullWidth 
                      helperText={touched.video && errors.video}
                      label="Video URLs"
                      placeholder="Video URL"
                      margin="normal"
                      name="video"
                      type={'url'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.video}
                      variant="outlined"
                      required
                    />
                  </Grid> */}
                  <Grid item md={5} xs={5} marginLeft={1}>
                    <FormControlLabel
                      label={''}
                      htmlFor={`langIcon`}
                      control={
                        <>
                          <Input
                            style={{ display: 'none' }}
                            onChange={event => {
                              handleChange(event)
                              onChangeLanguageIcon(event, setFieldValue)
                            }}
                            name={`langIcon`}
                            inputProps={{
                              accept: 'image/*',
                            }}
                            id={`langIcon`}
                            type="file"
                          />
                          <Button variant="contained" component="span">
                            Add Icon
                          </Button>
                        </>
                      }
                    ></FormControlLabel>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    {getFilePreview(values[`langIcon`])}
                  </Grid>
                  {/* <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.category}
                        label="Category"
                        name="category"
                        onChange={handleChange}
                        isRequired={true}
                        data={courseCategories}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.difficultyLevel}
                        label="Difficulty Level"
                        name="difficultyLevel"
                        onChange={handleChange}
                        isRequired={true}
                        data={courseDifficultyLevel}
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.duration && errors.duration)}
                      fullWidth
                      helperText={touched.duration && errors.duration}
                      label="Duration in hr"
                      margin="normal"
                      name="duration"
                      type="number"
                      min={1}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.duration}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      label="Description"
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      required
                      multiline
                      rows={4}
                    />
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Sort Order"
                      margin="normal"
                      inputProps={{ min: 0 }}
                      name="sortOrder"
                      type="number"
                      defaultValue={0}
                      onChange={handleChange}
                      value={values?.sortOrder}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoadingButton
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      name="Save"
                    ></LoadingButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddEditCourse
