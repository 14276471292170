import { Box, Container, Drawer } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import AddButton from 'src/components/helperComponents/AddButton'
// import DataTableNew from 'src/components/helperComponents/DataTable/DataTableNew'
import PlayerComponent from 'src/components/helperComponents/PlayerComponent'
import useQueryTable from 'src/hooks/useQueryTable'
import { DeleteLessonById, GetLessons } from 'src/redux/actions'
import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import { GetLessonTableColumns } from 'src/util/columns'

import AddEditLesson from '../components/lesson/AddEditLesson'

const LessonList = () => {
  const dispatch = useDispatch()

  const [customFilterQuery, setCustomFilterQuery] = useState()

  const [recordToEdit, setRecordToEdit] = useState()
  const [mediaUrl, setMediaUrl] = useState()
  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data, isLoading, isFetching, refetch } = useQueryTable({
    name: 'lessons',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
    additionalFilters: customFilterQuery,
  })

  const onDeleteRow = async record => {
    await dispatch(DeleteLessonById(record))
    refetch()
  }

  const columns = useMemo(() => GetLessonTableColumns(), [])

  return (
    <>
      <Helmet>
        <title>Lessons | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            type="Lesson"
            onClick={() => setRecordToEdit({})}
            setCustomFilterQuery={setCustomFilterQuery}
            dispatcher={GetLessons}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 450,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditLesson
              selectedRow={recordToEdit}
              refetch={refetch}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              onEditClick={setRecordToEdit}
              onDeleteClick={onDeleteRow}
              additionalFilters={customFilterQuery}
              data={data}
              name="lessons"
              isLoading={isLoading}
              isFetching={isFetching}
              setPagination={setPagination}
            />
          </Box>
          {mediaUrl && (
            <PlayerComponent
              url={mediaUrl}
              onDialogCancel={() => setMediaUrl()}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default LessonList
