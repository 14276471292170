export const getAllLeafColumnDefs = columns => {
  const allLeafColumnDefs = []
  const getLeafColumns = cols => {
    cols.forEach(col => {
      if (col.columns) {
        getLeafColumns(col.columns)
      } else {
        allLeafColumnDefs.push(col)
      }
    })
  }
  getLeafColumns(columns)
  return allLeafColumnDefs
}

export const getColumnId = columnDef =>
  columnDef.id ?? columnDef.accessorKey?.toString?.() ?? columnDef.header
