import { FormGroup, TextField, Stack, Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
// import VideoUploaderField from './Fields/VideoUploaderField'
import WatchVideoOrImageField from './Fields/WatchVideoOrImageField'
// Note: This form covers 2 use cases "Watch and record" and "Watch and type"

const WatchAndTypeOrRecord = ({ onDataChange, childData, isDisabled }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(childData)
  }, [childData])

  const changeHandler = (
    { target: { name, value, checked, files } },
    fieldLabel
  ) => {
    let finalValues
    if (files) {
      let file = files[0]
      file.fieldLabel = fieldLabel
      finalValues = {
        ...values,
        [name]: file,
      }
    } else {
      finalValues = {
        ...values,
        [name]: name === 'isImage' ? checked : value,
      }
    }
    setValues(finalValues)
    onDataChange(finalValues)
  }

  return (
    <Box sx={{ p: 2, border: '1px solid grey' }}>
      <FormGroup>
        {/* <VideoUploaderField
          onChange={e => changeHandler(e)}
          isDisabled={isDisabled}
          src={values.exerciseVideo}
        /> */}
        <WatchVideoOrImageField
          isImage={values.isImage}
          onChange={e => changeHandler(e)}
          isDisabled={isDisabled}
          value={values.isImage ? values.exerciseImage : values.exerciseVideo}
        />
        <Stack direction="row" spacing={2} margin={1}>
          <TextField
            fullWidth
            label="Video Url"
            name={'video'}
            onChange={changeHandler}
            value={values.video || ''}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Answer"
            name={'answer'}
            onChange={changeHandler}
            required
            value={values.answer || ''}
            variant="outlined"
          />
        </Stack>
      </FormGroup>
    </Box>
  )
}

export default WatchAndTypeOrRecord
