import { Button, CircularProgress } from '@material-ui/core'

const LoadingButton = props => (
  <Button
    color={props.color}
    disabled={props.disabled}
    fullWidth
    size={props.size}
    type={props.type}
    variant={props.variant}
  >
    {props.disabled && (
      <CircularProgress
        size={25}
        color={'inherit'}
        style={{ marginRight: '20px' }}
      />
    )}
    {props.name}
  </Button>
)

export default LoadingButton
