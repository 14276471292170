import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useDropdowns from 'src/hooks/useDropdowns'
import {
  AddEntertainmentVocabulary,
  GetChapters,
  GetCourses,
  GetLessons,
  GetUnits,
} from 'src/redux/actions'
import { filterByKeyValue } from 'src/util/helpers'
import * as Yup from 'yup'

import { StatusList } from '../../util/constants'
import DropdownMenu from '../helperComponents/DropdownMenu'

const AddEditVocabulary = ({ selectedRow, toggleDrawer }) => {
  const initialValues = {
    lesson: selectedRow?.lesson?._id || '',
    course: selectedRow?.course?._id || '',
    chapter: selectedRow?.chapter?._id || '',
    unit: selectedRow?.unit?._id || '',
    wordInRoman: selectedRow?.wordInRoman || '',
    wordInNative: selectedRow?.wordInNative || '',
    wordMeaningToDisplay: selectedRow?.wordMeaningToDisplay || '',
    video: selectedRow?.video || '',
    coin: selectedRow?.coin || '',
    isActive:
      selectedRow && selectedRow.isActive === undefined
        ? true
        : selectedRow && selectedRow?.isActive,
  }
  const dispatch = useDispatch()
  const { dropdowns } = useDropdowns()
  const [totalUnits, setTotalUnits] = useState([])
  const [totalChapters, setTotalChapters] = useState([])
  const [totalLessons, setTotalLessons] = useState([])

  useEffect(() => {
    setTotalUnits(dropdowns?.units)
    setTotalChapters(dropdowns?.chapters)
    setTotalLessons(dropdowns?.lessons)
  }, [dropdowns])

  const onCourseChange = useCallback(({ target: { value } }, setFieldValue) => {
    setTotalUnits(filterByKeyValue(dropdowns?.units, 'course', value))
    setTotalChapters([])
    setTotalLessons([])
    setFieldValue('chapter', '')
    setFieldValue('unit', '')
    setFieldValue('lesson', '')
  }, [])

  const onUnitChange = useCallback(({ target: { value } }, setFieldValue) => {
    setTotalChapters(filterByKeyValue(dropdowns?.chapters, 'unit', value))
    setTotalLessons([])
    setFieldValue('chapter', '')
    setFieldValue('lesson', '')
  }, [])

  const onChapterChange = useCallback(
    ({ target: { value } }, setFieldValue) => {
      setTotalLessons(filterByKeyValue(dropdowns?.lessons, 'chapter', value))
      setFieldValue('lesson', '')
    },
    []
  )

  useEffect(() => {
    dispatch(GetChapters())
    dispatch(GetUnits())
    dispatch(GetLessons())
    dispatch(GetCourses())
  }, [])

  const validationSchema = Yup.object().shape({
    video: Yup.string().required('Required'),
    course: Yup.string().required('Required'),
    unit: Yup.string().required('Required'),
    chapter: Yup.string().required('Required'),
    lesson: Yup.string().required('Required'),
    wordInRoman: Yup.string().required('Required'),
    wordInNative: Yup.string().required('Required'),
    wordMeaningToDisplay: Yup.string().required('Required'),
  })

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = values => {
    let dataToSubmit = selectedRow?._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    toggleDrawer(false)
    console.log('Data to submit', dataToSubmit)
    dispatch(AddEntertainmentVocabulary(dataToSubmit))
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container>
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} Vocabulary
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Course"
                        name="course"
                        data={dropdowns?.courses}
                        value={values.course}
                        onChange={event => {
                          handleChange(event)
                          onCourseChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Unit"
                        name="unit"
                        data={totalUnits}
                        value={values.unit}
                        onChange={event => {
                          handleChange(event)
                          onUnitChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Chapter"
                        name="chapter"
                        data={totalChapters}
                        value={values.chapter}
                        onChange={event => {
                          handleChange(event)
                          onChapterChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Lesson"
                        name="lesson"
                        data={totalLessons}
                        value={values.lesson}
                        onChange={handleChange}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Word in Roman"
                      placeholder="Word in Roman"
                      name="wordInRoman"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ minLength: 5 }}
                      value={values.wordInRoman}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Word in Devanagari/Native Language"
                      placeholder="Word in Devanagari/Native Language"
                      name="wordInNative"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{ minLength: 5 }}
                      value={values.wordInNative}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Meaning To Display Upon Uncover"
                      placeholder="Meaning To Display Upon Uncover"
                      name="wordMeaningToDisplay"
                      inputProps={{ minLength: 5 }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.wordMeaningToDisplay}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Video URL"
                      placeholder="Video URL"
                      name="video"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.video}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Vocabulary coin"
                      placeholder="Vocabulary coin"
                      name="coin"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.coin}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddEditVocabulary
