import * as React from 'react'
import PropTypes from 'prop-types'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Card } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import QuickSearchToolbar from './QuickSearchToolbar'
import CustomNoRowsOverlay from './CustomNoRowsOverlay'

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

const DataTable = ({
  data,
  columns,
  searchPlaceholder,
  loading,
  checkboxSelection,
  onSelectionChange,
}) => {
  const [searchText, setSearchText] = React.useState('')
  const [rows, setRows] = React.useState(data)

  const requestSearch = searchValue => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = data.filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field].toString())
      })
    })
    setRows(filteredRows)
  }

  React.useEffect(() => {
    setRows(data)
  }, [data, columns])

  return (
    <Card>
      <PerfectScrollbar>
        {/* <Box sx={{ height: 700, width: "100%" }}> */}
        <DataGrid
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rows={rows}
          columns={columns}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: event => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
          loading={loading}
          pageSize={10}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={onSelectionChange ?? null}
          disableSelectionOnClick={!checkboxSelection}
          autoHeight
          //autoPageSize
        />
        {/* </Box> */}
      </PerfectScrollbar>
    </Card>
  )
}

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  checkboxSelection: PropTypes.bool,
  //searchPlaceholder: PropTypes.string.isRequired,
}

export default DataTable
