import { Box, Button } from '@material-ui/core'
import { useLocation } from 'react-router'
import FilterPanel from 'src/pages/FilterPanel'

const AddButton = props => {
  const location = useLocation()
  const notShowFilter =
    location.pathname.indexOf('languages') !== -1 ||
    location.pathname.indexOf('entertainmentVideos') !== -1 ||
    location.pathname.indexOf('entertainmentVocabularies') !== -1 ||
    location.pathname.indexOf('customers') !== -1 ||
    location.pathname.indexOf('notifications') !== -1 ||
    location.pathname.indexOf('exerciseDetails') !== -1
  return (
    <Box
      display="flex"
      justifyContent={notShowFilter ? 'flex-end' : 'space-between'}
      alignItems={'center'}
      width={'100%'}
    >
      {props.setCustomFilterQuery ? (
        <FilterPanel
          dispatcher={props.dispatcher}
          setCustomFilterQuery={props.setCustomFilterQuery}
        />
      ) : null}
      <Box {...props}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button color="primary" variant="contained" onClick={props.onClick}>
            Add {props.type}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
export default AddButton
