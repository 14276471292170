import { Box, Container, Drawer } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import AddEditExcerciseMaster from 'src/components/exercise/AddEditExcerciseMaster'
import AddButton from 'src/components/helperComponents/AddButton'
import DialogComponent from 'src/components/helperComponents/DialogComponent'
import useQuery from 'src/hooks/useQuery'
import useQueryTable from 'src/hooks/useQueryTable'
import { DeleteExerciseMasterById, GetExerciseMasters } from 'src/redux/actions'
import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import { GetExerciseMasterTableColumns } from 'src/util/columns'

const ExerciseMasterList = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const { exerciseMasters } = useSelector(store => store.mainReducer)

  const [recordToEdit, setRecordToEdit] = useState()
  const [customFilterQuery, setCustomFilterQuery] = useState()
  useEffect(() => {
    dispatch(GetExerciseMasters())
  }, [])

  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data, isLoading, isFetching, refetch } = useQueryTable({
    name: 'exerciseMasters',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
    additionalFilters: customFilterQuery,
  })

  const onDeleteRow = async recordToDelete => {
    if (recordToDelete) {
      await dispatch(DeleteExerciseMasterById(recordToDelete))
      refetch()
    }
  }

  const columns = useMemo(() => GetExerciseMasterTableColumns(), [])

  return (
    <>
      <Helmet>
        <title>Exercise Masters | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            type="Exercise Master"
            onClick={() => setRecordToEdit({})}
            dispatcher={GetExerciseMasters}
            setCustomFilterQuery={setCustomFilterQuery}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 1000,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditExcerciseMaster
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
              refetch={refetch}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            {/* <DataTableNew
              data={exerciseMasters}
              columns={GetExerciseMasterTableColumns(
                setRecordToEdit,
                setRecordToDelete
              )}
              apiName={'exerciseMasters'}
              filters={query}
              customFilterQuery={customFilterQuery}
            /> */}
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              onEditClick={setRecordToEdit}
              onDeleteClick={onDeleteRow}
              additionalFilters={customFilterQuery}
              data={data}
              name="exerciseMasters"
              isLoading={isLoading}
              isFetching={isFetching}
              setPagination={setPagination}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default ExerciseMasterList
