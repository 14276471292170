import { Box, Container, Drawer } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import AddEditUser from 'src/components/AddEditUser'
import AddEditCourse from 'src/components/course/AddEditCourse'
import AddButton from 'src/components/helperComponents/AddButton'
import DataTableNew from 'src/components/helperComponents/DataTable/DataTableNew'
import DialogComponent from 'src/components/helperComponents/DialogComponent'
import PlayerComponent from 'src/components/helperComponents/PlayerComponent'
import { DeleteCourseById, GetUsers } from 'src/redux/actions'
import { GetUserTableColumns } from 'src/util/columns'
import Toaster from 'src/components/helperComponents/Toaster'

const CustomerList = () => {
  const dispatch = useDispatch()

  const { users } = useSelector(store => store.mainReducer)

  const [recordToEdit, setRecordToEdit] = useState()
  const [recordToDelete, setRecordToDelete] = useState()
  const [mediaUrl, setMediaUrl] = useState()
  const [showToaster, setShowToaster] = useState(false)

  const onDeleteRow = () => {
    setShowToaster(true)
    // if (recordToDelete) {
    //   dispatch(DeleteCourseById(recordToDelete))
    //   setRecordToDelete()
    // }
  }

  return (
    <>
      <Helmet>
        <title>User | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton type="User" onClick={() => setRecordToEdit({})} />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 500,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditUser
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <DataTableNew
              data={users}
              columns={GetUserTableColumns(setRecordToEdit, setRecordToDelete)}
              apiName="users"
            />
          </Box>
          {recordToDelete && (
            <DialogComponent
              title="Delete User?"
              description="Are you sure you want to delete user?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )}
        </Container>
        {showToaster && (
          <Toaster
            msg="This feature is not implemented yet."
            severity="error"
          />
        )}
      </Box>
    </>
  )
}

export default CustomerList
