import { useQuery } from '@tanstack/react-query'
import { fetchDropdowns } from 'src/api'

export default function useDropdowns() {
  const { data: dropdowns, loading } = useQuery(['dropdowns'], fetchDropdowns, {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  return {
    dropdowns,
    loading,
  }
}
