import {
  Button,
  Input,
  FormControlLabel,
  FormGroup,
  Radio,
  Stack,
  Box,
  Divider,
  Grid,
} from '@material-ui/core'

import React, { Fragment, useEffect, useState } from 'react'
import DeleteIconComponent from 'src/components/helperComponents/DeleteIconComponent'
import { getFilePreview } from 'src/util/helpers'
// Note: This form covers 1 use cases "Listen and choose right image option"

const RightImageOption = ({
  numberOfRows = 3,
  onDataChange,
  onMediaRemove,
  childData,
}) => {
  const [rows, addRows] = useState(numberOfRows)
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(childData)
  }, [childData])

  const changeHandler = ({ target: { name, value, files } }, fieldLabel) => {
    let finalValues
    if (files) {
      let file = files[0]
      file.fieldLabel = fieldLabel
      finalValues = {
        ...values,
        [name]: file,
      }
    } else {
      finalValues = {
        ...values,
        [name]: value,
      }
    }
    setValues(finalValues)
    onDataChange(finalValues)
  }
  return (
    <Box sx={{ p: 2, border: '1px solid grey' }}>
      <FormGroup>
        <Stack spacing={2} margin={1} key="audioStack">
          <Grid direction="row" container spacing={1}>
            <Grid item md={3} xs={3}>
              <FormControlLabel
                label={''}
                htmlFor={`audio`}
                control={
                  <>
                    <Input
                      style={{ display: 'none' }}
                      onChange={event => changeHandler(event, 'Audio')}
                      name={`audio`}
                      inputProps={{
                        accept: 'audio/mp3',
                      }}
                      id={`audio`}
                      type="file"
                    />
                    <Button variant="contained" component="span">
                      Audio
                    </Button>
                  </>
                }
              ></FormControlLabel>
            </Grid>
            <Grid item md={7} xs={7}>
              {getFilePreview(values[`audio`])}
            </Grid>
            <Grid item md={2} xs={2}>
              {values[`audio`] && (
                <DeleteIconComponent
                  onClick={() => onMediaRemove('audio', values)}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
        <Divider key="audioDivider" />
        <Stack spacing={2} margin={1} key="audioSlowStack">
          <Grid container spacing={1}>
            <Grid item md={3} xs={3}>
              <FormControlLabel
                label={''}
                htmlFor={`audioSlow`}
                control={
                  <>
                    <Input
                      style={{ display: 'none' }}
                      onChange={event => changeHandler(event, 'Slow_Audio')}
                      name={`audioSlow`}
                      inputProps={{
                        accept: 'audio/mp3',
                      }}
                      id={`audioSlow`}
                      type="file"
                    />
                    <Button variant="contained" component="span">
                      {'Slow_Audio'}
                    </Button>
                  </>
                }
              ></FormControlLabel>
            </Grid>
            <Grid item md={7} xs={7}>
              {getFilePreview(values[`audioSlow`])}
            </Grid>
            <Grid item md={2} xs={2}>
              {values[`audioSlow`] && (
                <DeleteIconComponent
                  onClick={() => onMediaRemove('audioSlow', values)}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
        <Divider key="audioSlowDivider" />
        {[...Array(rows)].map((el, index) => (
          <Fragment key={index + 'Fragment'}>
            <Stack key={index + 'Stack'} direction="row" spacing={2} margin={1}>
              <Grid container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`option${index + 1}File`}
                    control={
                      <>
                        <Input
                          style={{ display: 'none' }}
                          onChange={event =>
                            changeHandler(event, 'Image' + (index + 1))
                          }
                          name={`option${index + 1}File`}
                          inputProps={{
                            accept: 'image/*',
                          }}
                          id={`option${index + 1}File`}
                          type="file"
                        />
                        <Button variant="contained" component="span">
                          {'Image' + (index + 1)}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={5} xs={5}>
                  {getFilePreview(values[`option${index + 1}File`])}
                </Grid>
                {values[`option${index + 1}File`] !== 'null' &&
                  values[`option${index + 1}File`] && (
                    <>
                      <Grid item md={2} xs={2}>
                        {
                          <FormControlLabel
                            label={''}
                            control={
                              <Radio
                                name="answer"
                                onChange={changeHandler}
                                checked={
                                  values.answer === 'option' + (index + 1)
                                }
                                value={'option' + (index + 1)}
                              />
                            }
                            htmlFor="answer"
                            //   disabled={!values[`option${index + 1}File`]}
                          ></FormControlLabel>
                        }
                      </Grid>
                      <Grid item md={2} xs={2}>
                        {
                          <DeleteIconComponent
                            onClick={() =>
                              onMediaRemove(`option${index + 1}File`, values)
                            }
                          />
                        }
                      </Grid>
                    </>
                  )}
              </Grid>
            </Stack>
            <Divider key={index + 'Divider'} />
          </Fragment>
        ))}
      </FormGroup>
    </Box>
  )
}

export default RightImageOption
