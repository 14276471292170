import './index.css'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import App from './App'
import { UserProvider } from './contexts/UserContext'
import reducer from './redux/reducer'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
)
const STORE = createStore(reducer, enhancer)

ReactDOM.render(
  <BrowserRouter>
    <Provider store={STORE}>
      <UserProvider>
        <App />
      </UserProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
